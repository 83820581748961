import React, { Component } from 'react';
import axios from 'axios'
import { validEmailRegex, strongPasswordRegex, usernameRegex, mobilenumberRegex, validateForm } from '../../RegxConst'
import authservice from '../service/authservice';
import { TermsAndConditions } from './TermsAndConditions';
import { container, notifyUser, type } from '../../utility/notifyUser';
import Loader from '../../utility/Loader';
import styles from '../authentication.module.css';
import { ButtonStyle } from '../../UIElements/Buttons/ButtonStyle';
import SetAdvertiseLocation from '../../SetAdvertiseLocation';
import priviliege from '../../../assets/priviliege.gif'
import goodbye from '../../../assets/goodbye.gif'
import discount from '../../../assets/discount.gif'
import limited from '../../../assets/limited.gif'

class Registration extends Component {

    constructor(props) {
        super(props);
        this.addressOptions = [];
        this.selectedNeighbourhood = [];
        this.selectedLocality = [];
        this.selectedCity = [];
        this.selectedState = [];
        this.stateValidation = null;
        this.cityValidation = null;
        this.localityValidation = null;
        this.neighbourhoodValidation = null;
    }

    state = {
        firstName: '',
        isPopupOpen: false,
        lastName: '',
        username: '',
        email_address: '',
        password: '',
        confirmpassword: '',
        contact_number: '',
        tc: null,
        account_type: null,
        checked: false,
        age_checked: false,
        checkError: '',
        ageCheckError: '',
        radioError: '',
        referralKey: '',
        profilePhotoUrl: null,
        // profilePhoto: '',
        errors: {
            username: '',
            email_address: '',
            password: '',
            contact_number: '',
            refKey: '',
            confirmpassword: '',
        },
        termsModel: {
            open: false,
            model_type: '',
        },
        selectedOption: '',
        yearlyAccount: false,
        freeAccount: false,
        noTargetHalfYearlyAccount: false,
        halfYearlyPrepaidAccount: false,
        quarterlyPrepaidAccount: false,
        selectedNeighbourhood: [],
        selectedLocality: [],
        selectedCity: [],
        selectedState: [],
        fullAddress: [],
        prepaidStatus: '',
        prepaidPercentage: ''
    }

    componentDidMount() {
        this.prepaidOffer()
        Promise.all([
            axios.get(process.env.REACT_APP_BACKEND_URL + '/hpmgr/listing/getallstates'),
        ]).then(([getFullAddress]) => { // use arrow function to avoid loosing context, BTW you don't need to use axios.spread with ES2015 destructuring
            this.setState({ fullAddress: getFullAddress.data });
            this.props.fullAddress(getFullAddress.data)
            Loader.stopSpinner()
        }).catch(error => {
            Loader.stopSpinner()
            console.log(error, 'getting data failed');
        });

        window.scrollTo(0, 0); //while loading the component it will focus top
        if (authservice.getCurrentUser() !== null) {
            this.props.history.push('/')
        }
        const search = this.props.history.location.search
        const refKey = new URLSearchParams(search).get("ref")
        if (refKey) {
            this.handleRefKey(refKey)
        }
    }

    _handleState = (selectedStates) => {
        if (selectedStates.length)
            this.selectedState = selectedStates;
        else
            this.selectedState = [];
    }

    _handleCity = (e) => {
        if (e.length)
            this.selectedCity = e;
        else
            this.selectedCity = [];
    }
    _handleLocality = (e) => {
        if (e.length)
            this.selectedLocality = e;
        else
            this.selectedLocality = [];
    }
    _handleNeighbourhood = (e) => {
        if (e.length)
            this.selectedNeighbourhood = e
        else
            this.selectedNeighbourhood = [];
    }

    setAddressOptions = (options) => {
        this.addressOptions = options
    }

    setStateValidationMethod = (m) => {
        this.stateValidation = m;
    }

    setCityValidationMethod = (m) => {
        this.cityValidation = m;
    }

    setLocalityValidationMethod = (m) => {
        this.localityValidation = m;
    }

    setNeighbourhoodValidationMethod = (m) => {
        this.neighbourhoodValidation = m;
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let states = ''
        this.selectedState.map(data => {
            console.log(data)
            states = data.value
        })
        localStorage.setItem('state', states)
        let cities = ''
        this.selectedCity.map(data => {
            console.log(data)
            cities = data.value
        })
        localStorage.setItem('city', cities)
        let localities = ''
        this.selectedLocality.map(data => {
            console.log(data)
            localities = data.value
        })
        localStorage.setItem('locality', localities)
        let neighbourhoods = ''
        this.selectedNeighbourhood.map(data => {
            console.log(data)
            neighbourhoods = data.value
        })
        localStorage.setItem('neighbourhood', neighbourhoods)
        if (this.state.selectedOption === '') {
            this.setState({ radioError: "Please Select one Account type Option." })
        } else if (this.state.checked === false) {
            this.setState({ checkError: "Please Check The Check Box to Agree Upon The Terms & Conditions and Privacy Policy." })
        } else if (this.state.age_checked === false) {
            this.setState({ ageCheckError: "Please Check The Check Box to Agree Upon The Age above 18." })
        }
        else if (validateForm(this.state.errors)) {
            const data = {
                userName: this.state.username,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                password: this.state.password,
                mobileNumber: this.state.contact_number,
                email: this.state.email_address,
                referralKey: this.state.referralKey,
                yearlyAccount: this.state.yearlyAccount,
                freeAccount: this.state.freeAccount,
                halfYearlyPrepaidAccount: this.state.halfYearlyPrepaidAccount,
                noTargetHalfYearlyAccount: this.state.noTargetHalfYearlyAccount,
                quarterlyPrepaidAccount: this.state.quarterlyPrepaidAccount,
                stateId: states,
                cityId: cities,
                localityId: localities,
                neighbourhoodId: neighbourhoods,
                // profilePhoto: this.state.profilePhoto
            }
            if (this.selectedState.length < 1) {
                this.setState({ open: true })
                notifyUser("Location", "Select Any State", type.DANGER)
            }
            else if (this.cityValidation) {
                if (this.selectedCity.length < 1) {
                    this.setState({ open: true })
                    notifyUser("Location", "Select Any City", type.DANGER)
                }
                else if (this.localityValidation) {
                    if (this.selectedLocality.length < 1) {
                        this.setState({ open: true })
                        notifyUser("Location", "Select Any Locality", type.DANGER)
                    }
                    else if (this.neighbourhoodValidation) {
                        if (this.selectedNeighbourhood.length < 1) {
                            this.setState({ open: true })
                            notifyUser("Location", "Select Any Neighbourhood", type.DANGER)
                        }
                        else {
                            this.setState({ open: false })
                            localStorage.getItem('neighbourhood', neighbourhoods)
                            axios.post(process.env.REACT_APP_BACKEND_URL + '/abmgr/auth/save', data).then(response => {
                                Loader.stopSpinner();
                                if (response.data)
                                    this.props.history.push('/activation-success/' + this.props.match.params.verify)
                                // if (response.data)
                                //     this.props.history.push({
                                //         pathname: `/otp/${response.data.id}`,
                                //         search: `?auth_mod=${data.mobileNumber}`,
                                //         state: { email: data.email }
                                //     })
                            }).catch(error => {
                                if (error.response)
                                    notifyUser("Registration", error.response.data, type.DANGER, container.BOTTOM_RIGHT)
                            })
                        }
                    }
                    else {
                        this.setState({ open: false })
                        localStorage.getItem('locality', localities)
                        axios.post(process.env.REACT_APP_BACKEND_URL + '/abmgr/auth/save', data).then(response => {
                            Loader.stopSpinner();
                            if (response.data)
                                this.props.history.push('/activation-success/' + this.props.match.params.verify)
                            // if (response.data)
                            //     this.props.history.push({
                            //         pathname: `/otp/${response.data.id}`,
                            //         search: `?auth_mod=${data.mobileNumber}`,
                            //         state: { email: data.email }
                            //     })
                        }).catch(error => {
                            if (error.response)
                                notifyUser("Registration", error.response.data, type.DANGER, container.BOTTOM_RIGHT)
                        })
                    }
                }
                else {
                    this.setState({ open: false })
                    localStorage.getItem('city', cities)
                    axios.post(process.env.REACT_APP_BACKEND_URL + '/abmgr/auth/save', data).then(response => {
                        Loader.stopSpinner();
                        if (response.data)
                            this.props.history.push('/activation-success/' + this.props.match.params.verify)
                        // if (response.data)
                        //     this.props.history.push({
                        //         pathname: `/otp/${response.data.id}`,
                        //         search: `?auth_mod=${data.mobileNumber}`,
                        //         state: { email: data.email }
                        //     })
                    }).catch(error => {
                        if (error.response)
                            notifyUser("Registration", error.response.data, type.DANGER, container.BOTTOM_RIGHT)
                    })
                }
            }
            else {
                this.setState({ open: false })
                localStorage.getItem('state', states)
                axios.post(process.env.REACT_APP_BACKEND_URL + '/abmgr/auth/save', data).then(response => {
                    Loader.stopSpinner();
                    if (response.data)
                        this.props.history.push('/activation-success/' + this.props.match.params.verify)
                    // if (response.data)
                    //     this.props.history.push({
                    //         pathname: `/otp/${response.data.id}`,
                    //         search: `?auth_mod=${data.mobileNumber}`,
                    //         state: { email: data.email }
                    //     })
                }).catch(error => {
                    if (error.response)
                        notifyUser("Registration", error.response.data, type.DANGER, container.BOTTOM_RIGHT)
                })
            }
        }
        // }).catch(error => {
        //     console.log("Unable to upload your profile photo")
        //     notifyUser("Profile Picture", error.response.data, type.DANGER);
        //     Loader.stopSpinner()
        // })
    }

    refKeyAvailablityTimer = null
    handleRefKey = (e) => {
        const refkey = e.target ? e.target.value : e;
        this.setState({ referralKey: refkey })
        // if (this.refKeyAvailablityTimer) clearTimeout(this.refKeyAvailablityTimer)
        // let error = { ...this.state.errors }
        // if (refkey)
        //     this.refKeyAvailablityTimer = setTimeout(() => {
        //         axios.get(process.env.REACT_APP_BACKEND_URL + "/abmgr/auth/checkrefkey?refKey=" + refkey).then(
        //             response => {
        //                 error.refKey = response.data
        //                 this.setState({ errors: error })
        //             })
        //     }, 1000);
        // else {
        //     error.refKey = ''
        //     this.setState({ errors: error })
        // }
    }

    handleUserName = (e) => {
        e.preventDefault();
        if (this.userNameAvailabilityTimer) clearTimeout(this.userNameAvailabilityTimer)
        const userName = e.target.value;
        this.setState({ username: userName })
        let errors = { ...this.state.errors }
        this.userNameAvailabilityTimer = setTimeout(async () => {
            if (userName.length > 3 && userName.length < 16)
                if (usernameRegex.test(userName)) {
                    const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/abmgr/auth/checkusername?userName=" + userName)
                    errors.username = response.data
                } else
                    errors.username = 'Username contains invalid characters';
            else
                errors.username = userName.length < 4 ? 'Username should be minimum of 4 characters' : 'Too long username';
            this.setState({ errors })
        }, 1000);
    }

    userNameAvailabilityTimer = null;
    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        if (value) {
            switch (name) {
                case 'email_address': {
                    errors.email_address =
                        validEmailRegex.test(value)
                            ? ''
                            : 'Email is Not Valid!';
                    break;
                }
                case 'password': {
                    if (value.length < 8)
                        errors.password = 'Password should be minimum of 8 characters and must contain atleast 1 Lowercase , 1 Uppercase , 1 Numeric and 1 Special Character'
                    else
                        errors.password = strongPasswordRegex.test(value)
                            ? ""
                            : 'Password Must Contain at Least 1 Lowercase or 1 Uppercase , 1 Numeric and 1 Special Character';
                    errors.confirmpassword = this.state.confirmpassword && value !== this.state.confirmpassword ? 'Password Does Not Match' : ""
                    break;
                }
                case 'confirmpassword':
                    errors.confirmpassword = (value === this.state.password ? '' : 'Password Does Not Match');
                    break;
                case 'contact_number': {
                    errors.contact_number =
                        mobilenumberRegex.test(value)
                            ? (value.length < 10)
                                ? 'Mobile Number Must Have 10 Digits'
                                : ''
                            : 'Invalid Mobile Number (Hint: Do not enter STD/ISO code)'
                    break;
                }
                case 'tc': {
                    this.setState({ checked: !this.state.checked });
                    if (this.state.checked === false)
                        this.setState({ checkError: '' })
                    break;
                }
                case 'age_check': {
                    this.setState({ age_checked: !this.state.age_checked });
                    if (this.state.age_checked === false)
                        this.setState({ ageCheckError: '' })
                    break;
                }
                default:
                    break;
            }
            this.setState({ errors, [name]: value })
        } else {
            errors[name] = '';
        }
    }

    openModal = (type) => {
        this.setState({
            termsModel: {
                open: true,
                model_type: type,
            }
        });
    }

    closeModal = () => {
        this.setState({
            termsModel: {
                open: false,
                model_type: '',
            }
        });
    }

    profilePopup = () => {
        this.setState((prevState) => ({
            isPopupOpen: !prevState.isPopupOpen,
        }));
    };

    handleProfilePhoto = (e) => {
        const file = e.target.files[0];

        if (file && file.type.startsWith("image")) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({
                    profilePhotoUrl: e.target.result,
                    isPopupOpen: false  // Close the popup after successful image upload
                });
            };
            reader.readAsDataURL(file);

            this.selectedImage = file;
            this.profileDataChanged = true;
        }
    }

    prepaidOffer = () => {
        axios.get(process.env.REACT_APP_BACKEND_URL + '/abmgr/orders/get-offer-status').then(response => {
            console.log(response.data)
            this.setState({ prepaidStatus: response.data.offerStatus });
            this.setState({ prepaidPercentage: response.data.percentage })
        }).catch(error => {
            console.error("Error fetching notifications:", error);
        })
    }

    handleOptionChange = (changeEvent) => {
        const value = changeEvent.target ? changeEvent.target.value : '';
        this.setState({ yearlyAccount: value === 'option1', halfYearlyPrepaidAccount: value === 'option3', quarterlyPrepaidAccount: value === 'option2', noTargetHalfYearlyAccount: value === 'option4', freeAccount: value === 'option5', selectedOption: value }, () => {
            this.setState({ radioError: this.state.selectedOption ? '' : 'Please Select one Account type Option.' })
        });
    }


    render() {

        const { errors } = this.state;

        return (
            <>
                <div className='billBoard'>
                    <div className={`${styles.Registration} low_padding`} key={'reg-form'}>
                        <TermsAndConditions closeModal={this.closeModal} terms={this.state.termsModel} />
                        <div className={`grid_container ${styles.classified_add}`} >
                            <form onSubmit={this.handleSubmit}>
                                <h2 className={`${styles.registration_header}`}>Registration Form</h2>
                                {/* <label className={`${styles.reg_form_section_label}`} htmlFor="title"><strong>Subscription Models With Target </strong></label>
                                <div className="radio">
                                    <label className={`${styles.reg_form_section_label}`}>
                                        <input type="radio" name="account_type" value="option2" checked={this.state.selectedOption === 'option2'} onClick={() => this.openModal('quarter')} onChange={this.handleOptionChange} onInput={this.handleChange} />
                                        &nbsp; Quarter Year** Subscription - Rs.1770/-*
                                    </label> <br></br>
                                </div>
                                <div className="radio">
                                    <label className={`${styles.reg_form_section_label}`}>
                                        <input type="radio" name="account_type" value="option3" checked={this.state.selectedOption === 'option3'} onChange={this.handleOptionChange} onInput={this.handleChange} />
                                        &nbsp; Half Year** Subscription - Rs.3540/-*
                                    </label>
                                </div> */}

                                <div className="row form-row col-md-12">
                                    <label className='container_radio'> Half Year** Subscription - Rs.4500/-* 
                                    {/* Rs.3000/-* <img className='img-fluid' width='80px' src={limited} /> */}
                                        <input name="account_type" type="radio" value="option4" checked={this.state.selectedOption === 'option4'} onClick={() => this.openModal('paidHalf')} onChange={this.handleOptionChange} onInput={this.handleChange} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className="row form-row col-md-12">
                                    <label className='container_radio'> Annual Subscription - Rs.9000/-*
                                    {/* Rs.6000/-* <strong>(Freedom Offer till 15 Aug)</strong> */}
                                        <input name="account_type" type="radio" value="option1" checked={this.state.selectedOption === 'option1'} onClick={() => this.openModal('paidFull')} onChange={this.handleOptionChange} onInput={this.handleChange} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className="row form-row col-md-12">
                                    <label className='container_radio'> Unpaid Annual Subscription - Rs.9000/-*
                                        <br></br> Accumulate the Subscription and Upgrade
                                        <input name="account_type" type="radio" value="option5" checked={this.state.selectedOption === 'option5'} onClick={() => this.openModal('free')} onChange={this.handleOptionChange} onInput={this.handleChange} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>

                                <label className={`${styles.reg_form_section_label}`}>&nbsp;&nbsp;*( + 18% GST)
                                    <br></br>&nbsp; **Renewal will be in Annual Subscription.
                                    {/* <br></br>&nbsp; Note: The withdrawal option will not be available during Quarter Year Subscription Period. */}
                                </label>
                                <div className={`${styles.error}`}>{this.state.radioError}</div>
                                <div className={`${styles.reg_form_section} registration_padding`}>
                                    <div className="form-group row">
                                        <div className="col-md-4"> <label className={`${styles.reg_form_section_label}`} htmlFor="title">Name  </label></div>
                                        <div className="col-md-4">
                                            <input type="text" className="form-control" placeholder="First name"
                                                name="firstName" required value={this.state.firstName} noValidate
                                                onChange={(event) => this.setState({ firstName: event.target.value })}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <input type="text" className="form-control" placeholder="Surname"
                                                name="lastName" required value={this.state.lastName} noValidate
                                                onChange={(event) => this.setState({ lastName: event.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-4"> <label className={`${styles.reg_form_section_label}`} htmlFor="title">User name  </label></div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" placeholder="User name"
                                                name="username" required value={this.state.username} noValidate autoComplete="new-password"
                                                onChange={this.handleUserName}
                                            />
                                            <span className={`${styles.error}`}>{errors.username}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Password</label></div>
                                        <div className="col-md-8">
                                            <input type="password" className="form-control" placeholder="Password"
                                                name="password" autoComplete="new-password" required
                                                value={this.state.password} noValidate
                                                onChange={(event) => { this.setState({ password: event.target.value }); this.handleChange(event) }}
                                            />
                                            <span className={`${styles.error}`}>{errors.password}</span>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label className={`${styles.reg_form_section_label}`} htmlFor="title">Confirm password  </label></div>
                                        <div className="col-md-8">
                                            <input type="password" className="form-control" placeholder="Confirm password"
                                                name="confirmpassword" required value={this.state.confirmpassword} noValidate
                                                onChange={(event) => { this.setState({ confirmpassword: event.target.value }); this.handleChange(event) }}
                                            />
                                            <span className={`${styles.error}`}>{errors.confirmpassword}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Mobile number</label></div>
                                        <div className="col-md-8">
                                            <input className="form-control" placeholder="Mobile number" noValidate maxLength="10"
                                                name="contact_number" required value={this.state.contact_number}
                                                onChange={(event) => { this.setState({ contact_number: event.target.value }); this.handleChange(event) }}
                                            />
                                            <span className={`${styles.error}`}>{errors.contact_number}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Email address</label></div>
                                        <div className="col-md-8">
                                            <input className="form-control" placeholder="Email address" name="email_address"
                                                required value={this.state.email_address} noValidate
                                                onChange={(event) => { this.setState({ email_address: event.target.value }); this.handleChange(event) }}
                                            />
                                            <span className={`${styles.error}`}>{errors.email_address}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Invitation key</label></div>
                                        <div className="col-md-8">
                                            {(window.location.href === window.location.origin + '/registration?ref=' + this.state.referralKey) ? <input className="form-control" placeholder="Enter invitation key"
                                                name="referralKey" value={this.state.referralKey} noValidate
                                                onChange={this.handleRefKey} readOnly
                                            /> :
                                                <input className="form-control" placeholder="Enter invitation key"
                                                    name="referralKey" value={this.state.referralKey} noValidate
                                                    onChange={this.handleRefKey}
                                                />}
                                            <span className={`${styles.error}`}>{errors.refKey}</span>
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <div className="col-md-4">
                                            <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Location</label></div>
                                        <div className="col-md-8">
                                            <SetAdvertiseLocation
                                                //heading='Areas Served' isMulti
                                                setFilterValidationMethod={this.setLocationValidationMethod}
                                                allLocations={this.state.fullAddress}
                                                selectedStates={this.state.selectedState}
                                                selectedCities={this.state.selectedCity}
                                                selectedLocalities={this.state.selectedLocality}
                                                selectedNeighbourhoods={this.state.selectedNeighbourhood}
                                                _handleState={this._handleState}
                                                _handleCity={this._handleCity}
                                                _handleLocality={this._handleLocality}
                                                _handleNeighbourhood={this._handleNeighbourhood}
                                                addressOptions
                                                setAddressOptions={this.setAddressOptions}
                                                labelClass={'col-md-8'}
                                                setStateValidationMethod={this.setStateValidationMethod}
                                                setCityValidationMethod={this.setCityValidationMethod}
                                                setLocalityValidationMethod={this.setLocalityValidationMethod}
                                                setNeighbourhoodValidationMethod={this.setNeighbourhoodValidationMethod}
                                            />
                                            <p className='location_p'>Tell us your location to see what is near you. (If your location is not available, please select your nearest location.)</p>
                                        </div>

                                    </div>
                                    <div className="row form-group">
                                        <div className={`${styles.form_check} col-md-12`}>
                                            <label className={`form-check-label ${styles.reg_form_section_label}`}>
                                                <input type="checkbox" className={`${styles.form_check_input}`} name="age_check" age_checked={this.state.age_checked}
                                                    onInput={this.handleChange} noValidate readOnly />I am above 18 Years of Age & Have a PAN.
                                            </label>
                                            <div className={`${styles.error}`}>{this.state.ageCheckError}</div>
                                        </div>
                                    </div>
                                    <div className="row form-group">
                                        <div className={`${styles.form_check} col-md-12`}>
                                            <label className={`form-check-label ${styles.reg_form_section_label}`}>
                                                <input type="checkbox" className={`${styles.form_check_input}`} name="tc" checked={this.state.checked}
                                                    onInput={this.handleChange} noValidate readOnly />I have read and understood the &nbsp;
                                                <button type='button' onClick={() => this.openModal('terms')}
                                                    className={`btn ${styles.btn_link}`}>Terms &amp; Conditions,</button> &nbsp;
                                                <button type='button' onClick={() => this.openModal('privacy')}
                                                    className={`btn ${styles.btn_link}`}>Privacy Terms,</button><br></br>&nbsp; &nbsp; &nbsp; &nbsp;
                                                <button type='button' onClick={() => this.openModal('refund')}
                                                    className={`btn ${styles.btn_link}`}>Refund Policy</button> and the &nbsp;
                                                <button type='button' onClick={() => this.openModal('works')}
                                                    className={`btn ${styles.btn_link}`}>How It Works?</button> and agree.
                                            </label>
                                            <div className={`${styles.error}`}>{this.state.checkError}</div>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-12 text-right">
                                            <ButtonStyle type="submit" className="btn" text={'Submit'} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="large_rect">
                    <div className={`${styles.RegistrationMob} low_padding`} key={'reg-form'}>
                        <TermsAndConditions closeModal={this.closeModal} terms={this.state.termsModel} />
                        <div className={`grid_container ${styles.classified_add}`} >
                            <form onSubmit={this.handleSubmit}>
                                <h2 className={`${styles.registration_header}`}>Registration Form</h2>
                                <div className='col-md-12 promoter_radio'>
                                    {/* <label className={`${styles.reg_form_section_label}`} htmlFor="title"><strong>Subscription Models With Target </strong></label>
                                    <div className="radio">
                                        <label className={`${styles.reg_form_section_label}`}>
                                            <input type="radio" name="account_type" value="option2" checked={this.state.selectedOption === 'option2'} onClick={() => this.openModal('quarter')} onChange={this.handleOptionChange} onInput={this.handleChange} />
                                            &nbsp; Quarter Year** Subscription - Rs.1770/-*
                                        </label> <br></br>
                                    </div>
                                    <div className="radio">
                                        <label className={`${styles.reg_form_section_label}`}>
                                            <input type="radio" name="account_type" value="option3" checked={this.state.selectedOption === 'option3'} onChange={this.handleOptionChange} onInput={this.handleChange} />
                                            &nbsp; Half Year** Subscription - Rs.3540/-*
                                        </label>
                                    </div>*/}

                                    <div className="row form-row col-md-10">
                                        <label className='container_radio'>Half Year** Subscription - Rs.4500/-*  
                                        {/* <img className='img-fluid' width='60px' src={limited} /> */}
                                            <input name="account_type" type="radio" value="option4" checked={this.state.selectedOption === 'option4'} onClick={() => this.openModal('paidHalf')} onChange={this.handleOptionChange} onInput={this.handleChange} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="row form-row col-md-10">
                                        <label className='container_radio'>Annual Subscription - Rs.9000/-*
                                        {/* Rs.6000/-* <strong>(Freedom Offer till 15 Aug)</strong> */}
                                            {/* Rs.6000/-* <img className='img-fluid' width='60px' src={goodbye} /> Rs.4500/-* <img className='img-fluid' width='60px' src={priviliege} /> */}
                                            <input name="account_type" type="radio" value="option1" checked={this.state.selectedOption === 'option1'} onClick={() => this.openModal('paidFull')} onChange={this.handleOptionChange} onInput={this.handleChange} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="row form-row col-md-10">
                                        <label className='container_radio'>Unpaid Annual Subscription - Rs.9000/-*
                                            {/* Rs.6000/-* <img className='img-fluid' width='60px' src={goodbye} /> */}
                                            <br></br> Accumulate the Subscription and Upgrade
                                            <input name="account_type" type="radio" value="option5" checked={this.state.selectedOption === 'option5'} onClick={() => this.openModal('free')} onChange={this.handleOptionChange} onInput={this.handleChange} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <label className={`${styles.reg_form_section_label}`}>&nbsp;&nbsp;*( + 18% GST)
                                        <br></br>&nbsp; **Renewal will be in Annual Subscription.
                                        {/* <br></br>&nbsp; Note: The withdrawal option will not be available during Quarter Year Subscription Period. */}
                                    </label>
                                    <div className={`${styles.error}`}>{this.state.radioError}</div>
                                </div>
                                <div className={`${styles.reg_form_section}`}>
                                    <div className="form-group row">
                                        <div className="col-md-4"> <label className={`${styles.reg_form_section_label}`} htmlFor="title">Name  </label></div>
                                        <div className="col-md-4">
                                            <input type="text" className="form-control" placeholder="First name"
                                                name="firstName" required value={this.state.firstName} noValidate
                                                onChange={(event) => this.setState({ firstName: event.target.value })}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <input type="text" className="form-control" placeholder="Surname"
                                                name="lastName" required value={this.state.lastName} noValidate
                                                onChange={(event) => this.setState({ lastName: event.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-4"> <label className={`${styles.reg_form_section_label}`} htmlFor="title">User name  </label></div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" placeholder="User name"
                                                name="username" required value={this.state.username} noValidate autoComplete="new-password"
                                                onChange={this.handleUserName}
                                            />
                                            <span className={`${styles.error}`}>{errors.username}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Password</label></div>
                                        <div className="col-md-8">
                                            <input type="password" className="form-control" placeholder="Password"
                                                name="password" autoComplete="new-password" required
                                                value={this.state.password} noValidate
                                                onChange={(event) => { this.setState({ password: event.target.value }); this.handleChange(event) }}
                                            />
                                            <span className={`${styles.error}`}>{errors.password}</span>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label className={`${styles.reg_form_section_label}`} htmlFor="title">Confirm password  </label></div>
                                        <div className="col-md-8">
                                            <input type="password" className="form-control" placeholder="Confirm password"
                                                name="confirmpassword" required value={this.state.confirmpassword} noValidate
                                                onChange={(event) => { this.setState({ confirmpassword: event.target.value }); this.handleChange(event) }}
                                            />
                                            <span className={`${styles.error}`}>{errors.confirmpassword}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Mobile number</label></div>
                                        <div className="col-md-8">
                                            <input className="form-control" placeholder="Mobile number" noValidate maxLength="10"
                                                name="contact_number" required value={this.state.contact_number}
                                                onChange={(event) => { this.setState({ contact_number: event.target.value }); this.handleChange(event) }}
                                            />
                                            <span className={`${styles.error}`}>{errors.contact_number}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Email address</label></div>
                                        <div className="col-md-8">
                                            <input className="form-control" placeholder="Email address" name="email_address"
                                                required value={this.state.email_address} noValidate
                                                onChange={(event) => { this.setState({ email_address: event.target.value }); this.handleChange(event) }}
                                            />
                                            <span className={`${styles.error}`}>{errors.email_address}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Invitation key</label></div>
                                        <div className="col-md-8">
                                            {(window.location.href === window.location.origin + '/registration?ref=' + this.state.referralKey) ? <input className="form-control" placeholder="Enter invitation key"
                                                name="referralKey" value={this.state.referralKey} noValidate
                                                onChange={this.handleRefKey} readOnly
                                            /> :
                                                <input className="form-control" placeholder="Enter invitation key"
                                                    name="referralKey" value={this.state.referralKey} noValidate
                                                    onChange={this.handleRefKey}
                                                />}
                                            <span className={`${styles.error}`}>{errors.refKey}</span>
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <div className="col-md-4">
                                            <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Location</label></div>
                                        <div className="col-md-8">
                                            <SetAdvertiseLocation
                                                //heading='Areas Served' isMulti
                                                setFilterValidationMethod={this.setLocationValidationMethod}
                                                allLocations={this.state.fullAddress}
                                                selectedStates={this.state.selectedState}
                                                selectedCities={this.state.selectedCity}
                                                selectedLocalities={this.state.selectedLocality}
                                                selectedNeighbourhoods={this.state.selectedNeighbourhood}
                                                _handleState={this._handleState}
                                                _handleCity={this._handleCity}
                                                _handleLocality={this._handleLocality}
                                                _handleNeighbourhood={this._handleNeighbourhood}
                                                addressOptions
                                                setAddressOptions={this.setAddressOptions}
                                                labelClass={'col-md-8'}
                                                setStateValidationMethod={this.setStateValidationMethod}
                                                setCityValidationMethod={this.setCityValidationMethod}
                                                setLocalityValidationMethod={this.setLocalityValidationMethod}
                                                setNeighbourhoodValidationMethod={this.setNeighbourhoodValidationMethod}
                                            />
                                            <p className='location_p'>Tell us your location to see what is near you. (If your location is not available, please select your nearest location.)</p>
                                        </div>
                                    </div>
                                    <div className="row form-group">
                                        <div className={`${styles.form_check} col-md-12`}>
                                            <label className={`form-check-label ${styles.reg_form_section_label}`}>
                                                <input type="checkbox" className={`${styles.form_check_input}`} name="age_check" age_checked={this.state.age_checked}
                                                    onInput={this.handleChange} noValidate readOnly />I am above 18 Years of Age & Have a PAN.
                                            </label>
                                            <div className={`${styles.error}`}>{this.state.ageCheckError}</div>
                                        </div>
                                    </div>
                                    <div className="row form-group">
                                        <div className={`${styles.form_check} col-md-12`}>
                                            <label className={`form-check-label ${styles.reg_form_section_label}`}>
                                                <input type="checkbox" className={`${styles.form_check_input}`} name="tc" checked={this.state.checked}
                                                    onInput={this.handleChange} noValidate readOnly />I have read and understood the &nbsp;
                                                <button type='button' onClick={() => this.openModal('terms')}
                                                    className={`btn ${styles.btn_link}`}>Terms &amp; Conditions,</button> &nbsp;
                                                <button type='button' onClick={() => this.openModal('privacy')}
                                                    className={`btn ${styles.btn_link}`}>Privacy Terms,</button> &nbsp;
                                                <button type='button' onClick={() => this.openModal('refund')}
                                                    className={`btn ${styles.btn_link}`}>&nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; Refund Policy</button> and the &nbsp;
                                                <button type='button' onClick={() => this.openModal('works')}
                                                    className={`btn ${styles.btn_link}`}>How It Works?</button> and agree.
                                            </label>
                                            <div className={`${styles.error}`}>{this.state.checkError}</div>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-12 text-right">
                                            <ButtonStyle type="submit" className="btn" text={'Submit'} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>)
    }
}

export default Registration;