import React from 'react'
import { HCard } from './HCard'
import { VCard } from './VCard'
import { useViewportLookupCard } from '../../utility/useViewport';

export const CardsColumn = (props) => {
    const isLookupCard = useViewportLookupCard();
    return (
        <div>
            {isLookupCard ?
                <div className="row items_list">
                    {props.listings.map(listing => {
                        return props.type !== 'article' ? <VCard
                            {...props}
                            key={listing.hpId}
                            listing={listing}
                            isViewed={props.viewedContentIds.includes(listing.hpId)}
                        /> : <HCard
                            {...props}
                            key={listing.hpId}
                            listing={listing}
                            isViewed={props.viewedContentIds.includes(listing.hpId)}
                        />
                    })}
                </div>
                :
                <div className="row items_list">
                    {props.listings.map(listing => {
                        return <HCard
                            {...props}
                            key={listing.hpId}
                            listing={listing}
                            isViewed={props.viewedContentIds.includes(listing.hpId)}
                        />
                    })}
                </div>
            }
        </div>
    )
}