import React, { useEffect } from 'react';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'

export const CookiesPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="grid_container terms_of_service">
            <div className="row">
                <div className="col-md-12">
                    <h1 className={`${FooterStyle.footer_links_header} text-center`}>COOKIE POLICIES AND SIMILAR TECHNOLOGIES</h1>
                    <p>Please browse this policy on cookies and similar technologies carefully before using this website.</p>
                    <ol type="I" className={FooterStyle.margin}>
                        <li>
                            <h2>WHAT ARE COOKIES?</h2>
                            <p>Cookies are small text files with a little bit of data such as letters and numbers while you are using a website. To improve your experience on our website by remembering your preferences, we use cookies. Cookies are exchanged between your computer and the network server to read the ID and know what information exactly serves you.</p>
                        </li>
                        <li>
                            <h2>WHY DO WE USE COOKIES?</h2>
                            <p>We use cookies to smoothen your web experiences. Cookies perform various functions such as storing user’s preferences. In the absence of cookies, the user has to re-login after the user leaves the website. It makes cookies an important part of the internet experience.</p>
                            <ol type="1">
                                <li>
                                    <p>Manage your preferences and improve certain features of our platform, including collecting information about the date and time of your visit, browsing history, and language settings.</p>
                                </li>
                                <li>
                                    <p>To assist our safety features and discover any fraudulent activities.</p>
                                </li>
                                <li>
                                    <p>In order to analyze the access or use of our platform and its performance, we use this information to continuously maintain, operate and improve our services.</p>
                                </li>
                                <li>
                                    <p>Show you ads that are relevant to you because cookies help us to regulate the ads you see and measure their effectiveness.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h2>WHAT KIND OF COOKIES AND OTHER TECHNOLOGIES DO WE USE?</h2>
                            <p>Our Platform uses the subsequent classes of cookies and technologies:</p>
                            <ol type="1">
                                <li>
                                    <h3>Essential cookies:</h3>
                                    <p>Cookies that are essential to provide you with security and services you have requested. For example, these include the cookies that make it possible for you to stay logged into your aajubaaju.in user account, view & post contents, reviews, likes and comments. If you set your browser to block these cookies, then these functions and services may not work for you. In particular, we won't be able to save your preferences about cookies.</p>
                                </li>
                                <li>
                                    <h3>Functionality cookies</h3>
                                    <p>Cookies that are used to recognise you and remember your preferences or settings when you return to aajubaaju.in, so that we can provide you with a more personalised experience. For example, we store your geolocation so that when you revisit we can serve you ads and post in your location. We store country and state names to further personalise the contents you receive. We store some information to identify you when you are not logged in to personalise ads and other services.</p>
                                </li>
                                <li>
                                    <h3>Advertising cookies</h3>
                                    <p>Cookies that are used to collect information about your visit to our site, your location, the content and ads you have viewed.</p>
                                </li>
                            </ol>
                            <p>Our Platform may contain links to third-party websites or applications, as well as those of our partners. Please note that these third-party websites may additionally use cookies. we have a tendency to don't management those third-party websites and aren't liable for the cookies they set or access. If you click on one in all these links or apps, please note that everyone can have their own cookies policy. Therefore, please browse the cookies policy of alternative websites or apps before mistreatment them.</p>
                        </li>
                        <li>
                            <h2>DO WE HAVE THIRD-PARTY COOKIES OR PLUG-INS ON OUR PLATFORM?</h2>
                            <p>Ad networks and third-party providers such as web traffic analysis can collect information about your visit to the website from cookies, web beacons, and similar technologies provided by third parties. Our platform or anywhere on the internet can be used for advertising. Third-party cookies may be used and controlled by third parties. If they do, we are not responsible for their use.</p>
                            <ol type="1">
                                <li>
                                    <h3>Analytics Partners:</h3>
                                    <p>In order to better understand how you utilize our platform, we tend to work with varied analytics partners. We tend to permit analytics partners akin to Google and Facebook analytics to use cookies, SDKs and alternative connected technologies. For additional info on how Google and Facebook use your data, please visit   https://www.google.com/policies/technologies/partner-sites/, https://www.facebook.com/about/privacy/update.</p>
                                </li>
                                <li>
                                    <h3>Third-Party Plug-ins:</h3>
                                    <p>Our website also uses third-party share button plug-ins for Facebook, Twitter and WhatsApp. If you log in to these third-party providers, you can use this share button to share our ads on third-party platforms. These third parties can link your interaction with our platform to your account. Therefore, please read these third-party data protection guidelines before clicking the "Share" button.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h2>WHAT CAN YOU DO TO DELETE, DISABLE OR BLOCK COOKIES?</h2>
                            <p>Your browser will usually provide information about rejecting, deleting or blocking cookies.</p>
                            <p>You can disable receiving third-party cookies from Google Analytics in your advertising settings or at https://adssettings.google.com.</p>
                            <p>Please note that if you block the cookies we set on the website, you will not be able to access all or part of our services, such as being unable to save personalized settings such as login information.</p>
                        </li>
                    </ol>
                </div>
                <div className="listings_footer row">
                    <div className={`${styles.large_rect}`}>
                        <LargeRectangle spot={"-1"} />
                    </div>
                    <div className={`${styles.billBoard}`}>
                        <BillBoard spot={"-1"} />
                    </div>
                </div>
            </div>
        </div >
    )
}

