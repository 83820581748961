import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'
import connect from '../../assets/connect.jpg';
import vision from '../../assets/vision.svg';
import regisrec from '../../assets/regisrec.jpg'

export const AboutUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div class="grid_container terms_of_service">
            <div class="row">
                <div class="col-12">
                    <h1 className={`${FooterStyle.footer_links_header} text-center`}>About <span className={FooterStyle.logo_saffron}>aaju</span><span className={FooterStyle.logo_green}>baaju</span><span className={FooterStyle.logo_blue}>.in</span></h1>
                    <p>AajuBaaju.in is a subscription-based Software as a Service (SaaS Model) online platform developed by AajuBaaju eClassifieds Private Limited. AajuBaaju eClassifieds Private Limited was incorporated as a Private Limited Company based out of Telangana on July 17, 2019. It has also received recognition from Startup India and Startup Telangana.</p>

                    <h2 className={FooterStyle.abtH2}>VISIONS:</h2>
                    <ul>
                        <li>To provide a Recession & Lockdown Proof Non-Stop Stable Online Income to Every INDIAN from the Online Advertisement Industry.</li>
                        <li>To build INDIA ka Apna Website, an Indian Alternative for Indian Online Content Creators and Online Advertisers.</li>
                        <li>To provide an online platform suitable for TINY BUSINESSES to Promote their Business using the Power of Online Advertisement. All-in-One Promotional Tool with options to post Unlimited FREE Pages, Videos & Photos.</li>
                    </ul>

                    <h2 className={FooterStyle.abtH2}>CONCEPT:</h2>
                    <p>To increase the Purchasing Power of Consumers through the Online Advertisement Industry. Empower Yourself; Empower Others; Empower India. Let our children start to read "India is a developed country".</p>

                    <h2 className={FooterStyle.abtH2}>MOTTO:</h2>
                    <p>Every Effort Made & Every Minute Spent to Earn Money Online Should Never Go Waste. Earn from the first content from the first day.</p>

                    <h2 className={FooterStyle.abtH2}>SLOGAN:</h2>
                    <p>Ameeree Badaao.</p>

                    <h2 className={FooterStyle.abtH2}>INITIATIVE:</h2>
                    <p><strong>“INDIA ka Apna Website”</strong><br></br>
                        AajuBaaju.in is built on the motive to contribute towards "Self Reliant India"; to provide an alternate online platform for Indian content creators and online advertisers. AajuBaaju.in is a combination of user-generated content websites such as video streaming, photo story, blog, and review writing; combination of online directory websites such as business listing, event listing, and job posting websites. "A 7-in-1 Website."</p>

                    <h2 className={FooterStyle.abtH2}>SEARCH PLATFORM:</h2>
                    <p>AajuBaaju.in is fundamentally a "Highly Localized Information Providing Internet Platform" designed to store neighbourhood level information in 7 segments namely videos, photos, pages, reviews, business, events & jobs. A single search will yield information from 7 segments. AajuBaaju.in uses the crowdsourcing model, "For the Indians; By the Indians" concept to source information in these 7 segments.</p>

                    <h2 className={FooterStyle.abtH2}>HOME SWEET HOME IS THE MANTRA:</h2>
                    <p>AajuBaaju.in aids "Vocal for Local" concept. AajuBaaju.in focuses on the neighbourhood of a visitor. A visit to AajuBaaju.in is designed to be an online tour of their neighbourhood. Visitors will see all the businesses around them, upcoming events around them, jobs around them, & advertisements of the businesses around them. The visitors will see all the information which can be used by them in their day-to-day life. The information will not just be useful information, but also usable information.</p>

                    <h2 className={FooterStyle.abtH2}>ONLINE EARNING FOR EVERYONE:</h2>
                    <p>AajuBaaju.in has developed a strategy to provide a "Recession & Lockdown Proof Stable Online Income Source" for everyone from the online advertisement industry. Anyone, according to their skill, can contribute content in one or more segments & earn from the advertisements displayed in their content. AajuBaaju.in also has a strategy to earn by watching advertisements catering to the population having no skill in any of these 7 segments.</p>

                    <h2 className={FooterStyle.abtH2}>DIGITAL ENTREPRENEUR:</h2>
                    <p>AajuBaaju.in has a "Ab Sab ka Skills Hai Apna Skills" 2 levels loyalty earning program to provide digital entrepreneurship opportunities to set up huge online passive income.</p>
                    <ul>
                        <li>Level 1 enables an AajuBaajuian to earn advertisement revenue share from others' content creation skills apart from earning from one's own skill. A video content creator can onboard a content writer, a photo story creator, a review writer, etc., to earn advertisement revenue share from their skills.</li>
                        <li>Level 2 enables an AajuBaajuian to utilize others' socializing & influencing skills to increase their community size of other content creators.</li>
                    </ul>

                    <h2 className={FooterStyle.abtH2}>FOR TINY BUSINESSES:</h2>
                    <p>Problem: The present online platforms for advertisements are either complex or expensive or both for the tiny businesses to use the power of online advertising to improve their business.</p>
                    <p>Solution: AajuBaaju.in is suitable for tiny businesses to promote their business online within their locality with unlimited pages, unlimited photos & unlimited videos at zero cost. AajuBaaju.in has a "Spend No More; Just Earn More" concept for business promotion. Apart from getting online visibility, businesses can earn advertisement revenue share from their promotions themselves to meet their promotional cost.</p>

                    <h2 className={FooterStyle.abtH2}>NO MORE HARDSHIPS TO EARN MONEY ONLINE:</h2>
                    <p>AajuBaaju.in has a motto of "Every Effort Made & Every Minute Spent to Earn Money Online Should Never Go Waste." AajuBaaju.in has researched and eliminated the hardships causing wastage of effort and time spent to earn money online.</p>

                    <h2 className={FooterStyle.abtH2}>ELIMINATED THE HARDSHIPS OF VIDEO CONTENT CREATORS:</h2>
                    <p>Problem: The major hardship for video content creators is the monetization rules prevailing in the leading advertisement revenue sharing platforms.</p>
                    <p>Solution: AajuBaaju.in has no monetization rules such as minimum subscribers/page likes, minimum watch hours/followers, etc. Video content creators can post their video content in "AajuBaaju My Videos& AajuBaaju My Clips" segments and can start earning advertisement revenue share from their first video from the first day onwards. A video content creating AajuBaajuian can onboard other video content creators from multiple different niches and earn advertisement revenue share from their videos as well, as part of a loyalty earning program. An AajuBaajuian posting videos in the education niche can onboard a video content creator from the tech niche, beauty niche, cooking niche, etc., and earn loyalty earning.</p>

                    <h2 className={FooterStyle.abtH2}>ELIMINATED THE HARDSHIPS OF CONTENT WRITERS:</h2>
                    <p>Problem: The major hardships for creative content writers are maintaining a website, researching keywords & SEO to drive traffic to their website, etc. Many content writers are unable to use their writing skills to earn money online because of these unwanted hurdles.</p>
                    <p>Solution: Creative content writers can post their articles and blog content in the "AajuBaaju My Pages" segment and can start earning advertisement revenue share from their first content from the first day onwards. Creative content writers can concentrate only on content writing now, their real skill. A creative content writing AajuBaajuian can onboard other creative content writers from multiple different niches and earn money from their writing as well, as part of the loyalty earning program. An AajuBaajuian writing in the education niche can onboard content writers from the tech niche, beauty niche, cooking niche, etc., and earn loyalty income.</p>

                    <h2 className={FooterStyle.abtH2}>NEW ADVERTISEMENT REVENUE SHARING SEGMENTS:</h2>
                    <p>Problem: The present online earning opportunities require specific skill sets to earn money online. A large portion of the population does not have these specific skills.</p>
                    <p>Solution: AajuBaaju.in has added additional advertisement revenue sharing segments to cover additional skill sets. AajuBaaju.in has "AajuBaaju My Photos" segment in which an AajuBaajuian can create a photo story out of the photos taken on vacation, function, event, etc., or create product catalogues for their business and earn revenue share from the advertisements displayed in their photo story. AajuBaaju.in has "AajuBaaju My Album" segment in which an AajuBaajuian can create a personal photo album out of the photos taken to capture memories and earn revenue share from the advertisements displayed in their photo album. AajuBaaju.in has "AajuBaaju My Reviews" segment in which an AajuBaajuian can write a review about products, places, businesses, etc., and earn revenue share from the advertisements displayed in their review. AajuBaaju.in has "Business Listings" segment in which an AajuBaajuian can list businesses, service providers, etc., and earn revenue share from the advertisements displayed in their business listing. AajuBaaju.in has "Event Listings" segment in which an AajuBaajuian can list upcoming events and earn revenue share from the advertisements displayed in their event listing. AajuBaaju.in has "Job Listings" segment in which an AajuBaajuian can list job vacancies and earn revenue share from the advertisements displayed in their job listing.</p>

                    <h2 className={FooterStyle.abtH2}>EARN BY WATCHING ADVERTISEMENTS:</h2>
                    <p>Problem: There is a vast portion of the population still remaining who cannot make use of these 7 segments.</p>
                    <p>Solution: AajuBaaju.in has created an "Easy to Learn" strategy to earn by watching advertisements to cover this portion of the population with zero skills.</p>

                    <h2 className={FooterStyle.abtH2}>NO MINIMUM WITHDRAWAL CRITERIA:</h2>
                    <p>aajubaaju.in does not hold any earning which can be withdrawn in the name of minimum withdrawal criteria or withdrawal frequency. AajuBaajuians can withdraw their earnings daily, weekly, monthly or in any frequency of their choice. Any amount earned more than Re.1/- can be withdrawn.</p>
                    <div className='text-center p-1'>
                        <h1 className={`${FooterStyle.footer_links_header} text-center`}><span className={FooterStyle.logo_saffron}>aaju</span><span className={FooterStyle.logo_green}>baaju</span><span className={FooterStyle.logo_blue}>.in</span></h1>
                        <span className={FooterStyle.spanCenter}>in alignment with India’s</span> <br></br><span className={FooterStyle.spanCenter}>Vision.</span>
                    </div>
                    <div className='text-center'><img className="img-fluid" src={vision} /></div>
                    <p className='text-center' style={{fontSize: '26px'}}>Now INDIA along with the Honourable Prime Minister has an Indian Option, an Indian Platform to Promote.</p>
                    <div className='text-center p-1'><img className="img-fluid" src={connect} /></div>
                    <p className='text-center' style={{fontSize: '18px'}}>When we all are in the platform, the Prime Minister's share page also will look as above with <span style={{color: '#FF9933'}}>Aaju</span><span style={{color: '#138808'}}>Baaju</span> logo.</p>
                    <p className='text-center' style={{fontSize: '26px'}}>Let's all together make this happen.</p><br></br>
                    <div className='text-center p-1'><img className="img-fluid" src={regisrec} /></div><br></br>
                </div>
                <div className="listings_footer row">
                    <div className={`${styles.large_rect}`}>
                        <LargeRectangle spot={"-1"} />
                    </div>
                    <div className={`${styles.billBoard}`}>
                        <BillBoard spot={"-1"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

