import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import BillBoard from '../../advertisements/BillBoard';
import LargeRectangle from '../../advertisements/LargeRectangle';
import { TitleHeading } from '../../UIElements/headings/TitleHeading';
import Loader from '../../utility/Loader';
import { useViewportBillBoard } from '../../utility/useViewport';
import { CardsRow } from '../cards/CardsRow';
import listingService from '../service/listingService';

export const BusinessListings = (props) => {
    const coords = useSelector(state => state.current_coords);
    const [listings, setListings] = useState([]);
    const viewedListings = useSelector(state => state.viewedListings);
    const isBillboard = useViewportBillBoard();

    const getListingDetails = (listing) => {
        const searchCriteria = listingService.getListingSettings(listing, coords);
        const businessName = listing.businessName.replace(/[^\w\s^&]/gm, ' ').trim().replace(/[\s]/gm, '-')
        const listingPath = encodeURI(`/l/${businessName}/${listing.hpId}`);
        props.history.push(listingPath, {
            place: 'sponsored',
            isViewed: viewedListings.includes(listing.hpId),
            searchCriteria: searchCriteria,
        });
    }

    useEffect(() => {
        if (!coords.latitude)
            return;

        Loader.runSpinner()
        listingService.getAllListingsByType(coords, 'business').then(data => {

            data.sort((l1, l2) => {
                if (l1.businessPackagePriority > l2.businessPackagePriority) return 1;
                if (l2.businessPackagePriority < l2.businessPackagePriority) return -1;

                if (l1.totalRatings > l2.totalRatings) return 1;
                if (l2.totalRatings < l2.totalRatings) return -1;

                if (l1.totalReviews > l2.totalReviews) return 1;
                if (l2.totalReviews < l2.totalReviews) return -1;

                return 0;
            });

            Loader.stopSpinner()
            setListings(data);
        }).catch(() => {
            Loader.stopSpinner()
        })
    }, [coords])

    return (
        listings.length > 0 ?
            <div>
                <TitleHeading text={'Sponsored Business Listings'} />
                <CardsRow listings={listings}
                    showContent={getListingDetails}
                    viewedContentIds={viewedListings}
                    type={'business'} section='sponsored' />
                <div className='row'>
                    {isBillboard ? <LargeRectangle spot={props.bannerAdSpot} />
                        : <BillBoard spot={props.bannerAdSpot} />}
                </div>
            </div>
            : <></>
    )
}