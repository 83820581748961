import React, { useEffect } from 'react';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'
import referrals from '../../assets/referrals.jpg'

export const ViewsWork = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="grid_container terms_of_service">
            <div className="row">
                <div className="col-md-12">
                    <h1 className={`${FooterStyle.footer_links_header} text-center`}>HOW IT WORKS?</h1>
                    <ul>
                        <li>
                            <h2>SUBSCRIPTION MODELS:</h2>
                            <p>aajubaaju.in is a subscription based SaaS model website. There are 3 subscription models. NO COMPULSORY TARGET APPLICABLE.</p>
                            {/* <p><strong>No Target Subscription Models</strong></p> */}
                            <div style={{ border: '2px solid black', padding: '8px' }}>
                                <p style={{ textAlign: 'center', fontWeight: '600' }}>
                                    One-Time Half Year Subscription - Rs.5310/- (incl. of 18% GST) Validity - 6 Months
                                </p>
                                <p>
                                    <span style={{ fontWeight: '600' }}>Target:</span> There are no targets for AD view and Content Earning.
                                </p>
                                <p>
                                    <span style={{ fontWeight: '600' }}>Withdrawal Eligibility:</span><br></br>
                                    Eligible to withdraw the Ad View earnings @45 paise non-stop for the whole 6 months.
                                </p>
                                <p>
                                    <span style={{ fontWeight: '600' }}>Note:</span> <br></br>
                                    This is a one-time subscription and the renewal will be in No Target Annual Subscription. The Ad View earning will remain only at 45 paise even upon introducing AajuBaajuians in this 6 months period. The Ad View earnings will increase based on the no. of AajuBaajuians introduced during the 6 months only after the renewal. Introducing AajuBaajuians is optional. However, if you introduce you can earn more. There is no upper limit to introduce AajuBaajuians.
                                </p>
                            </div><br></br>
                            <div style={{ border: '2px solid black', padding: '8px' }}>
                                <p style={{ textAlign: 'center', fontWeight: '600' }}>
                                    Annual Subscription – Rs.10620/- (incl. of 18% GST) Validity – 1 Year
                                </p>
                                <p>
                                    <span style={{ fontWeight: '600' }}>Target:</span> There are no targets for AD view and Content Earning.
                                </p>
                                <p>
                                    <span style={{ fontWeight: '600' }}>Withdrawal Eligibility:</span><br></br>
                                    Eligible to withdraw the Ad View earnings @45 paise non-stop for the whole 1 year. The Ad View earning will also increase to 60 paise, 75 paise & 90 paise based on the no. of AajuBaajuians introduced immediately.
                                </p>
                                <p>
                                    <span style={{ fontWeight: '600' }}>Note:</span> <br></br>
                                    Introducing AajuBaajuians is optional. However, if you introduce you can earn more. There is no upper limit to introduce AajuBaajuians.
                                </p>
                            </div><br></br>
                            <div style={{ border: '2px solid black', padding: '8px' }}>
                                <p style={{ textAlign: 'center', fontWeight: '600' }}>
                                    Unpaid Annual Subscription - Earn the Subscription (Rs.10620) and Upgrade – Validity - 1 Year
                                </p>
                                <p>
                                    <span style={{ fontWeight: '600' }}>Target:</span> There are no targets for AD view and Content Earning.
                                </p>
                                <p>
                                    <span style={{ fontWeight: '600' }}>Withdrawal Eligibility:</span><br></br>
                                    Accumulate AD view earnings @0.45p per AD until upgrade. The accumulated earning can be used to pay the Annual Subscription amount of Rs.10620 and upgrade.
                                    Eligible to withdraw the Ad View earnings only after upgrade.
                                </p>
                                <p>
                                    <span style={{ fontWeight: '600' }}>Note:</span> <br></br>
                                    Introducing AajuBaajuians is optional. However, if you introduce you can earn more. There is no upper limit to introduce AajuBaajuians.
                                </p>
                            </div><br></br>

                        </li><br></br>
                        <li>
                            <h2>AD VIEW EARNINGS:</h2>
                            <p>An AajuBaajuian will earn money for watching advertisements published in various pages of the website. The AajuBaajuian has to keep the advertisement in focus for 30 seconds, until a green tick mark displays in the bottom right corner, to earn money for watching that advertisement. The earning per advertisement will be based on the stages depending on the no. of AajuBaajuians introduced.</p>
                            <p>Below is the earning metrics:</p>
                            <table>
                                <tr className="table-row extra_height">
                                    <th>No. of AajuBaajuians in Level-1</th>
                                    <th>Stage</th>
                                    <th>Earning per Advertisement</th>
                                </tr>
                                <tr className="table-row extra_height">
                                    <td>0</td>
                                    <td>Beginner</td>
                                    <td>Rs.0.45/-</td>
                                </tr>
                                <tr className="table-row extra_height">
                                    <td>1-3</td>
                                    <td>Intermediate</td>
                                    <td>Rs.0.60/-</td>
                                </tr>
                                <tr className="table-row extra_height">
                                    <td>4-10</td>
                                    <td>Master</td>
                                    <td>Rs.0.75/-</td>
                                </tr>
                                <tr className="table-row extra_height">
                                    <td>11+</td>
                                    <td>Expert</td>
                                    <td>Rs.0.90/-</td>
                                </tr>
                            </table><br></br>
                            <h2>Promotion:</h2>
                            <p>An AajuBaajuian will get promoted when he/she introduce aajubaaju.in to others and introduce them as AajuBaajuians.</p>
                            <p>The AajuBaajuian will get promoted to<br></br>
                                Intermediate Stage when the AajuBaajuian introduces 1 AajuBaajuian.<br></br>
                                Master Stage when the AajuBaajuian introduces 4 AajuBaajuians.<br></br>
                                Expert Stage when the AajuBaajuian introduces 11 AajuBaajuians.
                            </p>
                            <h2>Demotion:</h2>
                            <p>An AajuBaajuian will get demoted when an AajuBaajuian introduced by him/her gets blocked or deleted due to inactiveness* or malpractice or for any other reason affecting aajubaaju.in.</p>
                            <p>The AajuBaajuian will get demoted to<br></br>
                                Beginner Stage if the AajuBaajuian count reduces to 0 AajuBaajuian.<br></br>
                                Intermediate Stage if the AajuBaajuian count reduces to 3 AajuBaajuians.<br></br>
                                Master Stage if the AajuBaajuian count reduces to 10 AajuBaajuians.
                            </p>
                        </li>

                        <li>
                            <h2>CONTENT EARNINGS:</h2>
                            <p>AajuBaajuians can post content in aajubaaju.in as per their talent and skill set. They can earn 75 paise for each advertisement displayed in the content posted by them when Non-AajuBaajuians or AajuBaajuians watch the advertisements displayed in their content for 30 seconds.</p>
                            <p><strong>There are different segments namely…</strong></p>
                            <p>
                                AajuBaaju My Videos<br></br>
                                AajuBaaju My Clips<br></br>
                                AajuBaaju My Photos<br></br>
                                AajuBaaju My Album<br></br>
                                AajuBaaju My Pages<br></br>
                                AajuBaaju My Reviews<br></br>
                                Business<br></br>
                                Events<br></br>
                                Jobs
                            </p>
                            <p><strong>Content Earnings Capping:</strong></p>
                            <p>Earn for the 1st 200 Ad Views from every 5000 Ad Views per Day. The Ad Views count is the Total No. of Ad Views in "Advertisements in Sponsored Listings" + "Advertisements in Featured Listings" + "Advertisements in Listings". Only 100 Ad views will be calculated in each 200 Ad view when watched by the AajuBaajuians.</p>
                        </li>
                        <li>
                            <h2>LOYALTY EARNINGS:</h2>
                            <img className="img-fluid" src={referrals} alt="referrals" />
                            <p>CAN generate Invitation Link to invite people even before upgrading their account.</p>
                            <p>Loyalty Earning will be earned only from the AajuBaajuians introduced in Paid Subscriptions.</p>
                            <p>NO loyalty earning from the AajuBaajuians introduced in Unpaid Subscriptions.</p>
                            <p>Ability to withdraw loyalty earning only after the upgrade.</p>
                            <p>The AajuBaajuians will accumulate 1/3rd equivalent of the earnings earned by the AajuBaajuians in their 2 Levels AajuBaajuian Community as Loyalty Earnings.</p>
                            <p>The accumulated Loyalty Earnings can be withdrawn by meeting the following conditions:</p>
                            <p>
                                <strong>Type 1:</strong><br></br> 15 Level-1 Intermediate AajuBaajuians for Rs.50,000/-*<br></br>10 Level-1 Intermediate AajuBaajuians for Rs.25,000/-*<br></br>
                                <strong>Type 2:</strong><br></br> 2 Paid Listings + 4 Advertisements for Rs.50,000/-<br></br>2 Paid Listings + 2 Advertisements for Rs.25,000/-<br></br>
                                <strong>Type 3:</strong><br></br> 5 Advertisements for Rs.50,000/-<br></br>3 Advertisements for Rs.25,000/-</p>
                            <p>** AajuBaajuians introduced in Quarter Year subscription will not be considered for Type 1.</p>
                        </li>

                        <li>
                            <h2>ADVERTISEMENT:</h2>
                            <p>The advertisements are charged on per view basis. The current price is Rs.5/- per view. The advertiser must by buy a minimum of 1000 views per order. The advertisement will be charged for 1 view when a visitor views the advertisement for 30 seconds. When an advertisement is viewed by a visitor, it will not be shown again to that visitor until next day. When an advertisement is not viewed by a visitor for 30 seconds, the view will not be charged.</p>
                            <p>The advertisements are posted as campaigns. There are 5 types of advertisements namely Leaderboard, Banner, Large Rectangle, Billboard and Popup. An advertisement campaign can have any one of these advertisement types; one or more advertisement types; all advertisement types.</p>
                            <p>The view count is applicable for the campaign as a whole, i.e., views purchased by the advertiser is for the whole campaign and not for an individual advertisement type in the campaign.</p>
                            <p>Example: An advertiser purchases 1000 view count for his advertisement campaign which has a Leaderboard advertisement type and a Banner advertisement type.</p>
                            <ol type="a">
                                <li>
                                    <p>The Leaderboard advertisement first gets displayed in a page and a visitor views it for 30 seconds. 1 view count will be deducted from 1000 view count purchased for the campaign. Now the view count balance is 999.</p>
                                </li>
                                <li>
                                    <p>Next the Banner advertisement gets displayed in a page and a visitor views it for 30 seconds. Again 1 view count will be deducted from 999 view count balance of the campaign. Now the view count will be 998.</p>
                                </li>
                                <li>
                                    <p>Now both the Leaderboard and the Banner advertisements gets displayed in a page and a visitor views both the advertisements for 30 seconds each. This time 2 view counts will be deducted from 998 view count balance of the campaign. Now the view count will be 996.</p>
                                </li>
                            </ol>
                        </li>

                        <li>
                            <h2>SPONSORED LISTINGS:</h2>
                            <p>The listings which are opted to display in the home page are called sponsored listings.The sponsored listings are charged on per click basis. The current price is Rs.5/- per. A minimum of 1000 clicks should be purchased per order. The sponsored listing will be charged for 1 click every time it gets clicked in the home page.</p>
                        </li>

                        <li>
                            <h2>FEATURED LISTINGS:</h2>
                            <p>The listings which are opted to display in the lookup page are called featured listings.The featured listings are charged on per click basis. The current price is Rs.5/- per. A minimum of 1000 clicks should be purchased per order. The featured listing will be charged for 1 click every time it gets clicked in the lookup page featured section.</p>
                        </li>
                        <hr className={FooterStyle.line}></hr>
                        <hr className={FooterStyle.line}></hr>
                        <li>
                            <p><strong>Target Based Subscription Models - DISCONTINUED</strong></p>
                            <p><strong>Note:</strong> Existing AajuBaajuians will continue to be in the targets applicable.</p>
                            <div style={{ border: '2px solid black', padding: '8px' }}>
                                <p style={{ textAlign: 'center', fontWeight: '600' }}>One-Time Quarter Year Subscription – Rs.1770/- (incl. of 18% GST)
                                    Validity – 3Months
                                </p>
                                <p><span style={{ fontWeight: '600' }}>Target:</span><br></br>
                                    Must introduce 4 AajuBaajuians within the subscription validity period of 3 Months.
                                </p>
                                <p>
                                    <span style={{ fontWeight: '600' }}>Withdrawal Eligibility:</span><br></br>
                                    No withdrawal eligibility for 3 months. All the earnings will get accumulated for 3 months. The accumulated earning can be withdrawn only after the renewal upon meeting the target.
                                </p>
                                <p>
                                    <span style={{ fontWeight: '600' }}>Note:</span><br></br>
                                    This is a one-time subscription and the renewal will be in Target Based Annual Subscription.
                                    No refund applicable.
                                </p>
                            </div> <br></br>
                            <div style={{ border: '2px solid black', padding: '8px' }}>
                                <p style={{ textAlign: 'center', fontWeight: '600' }}>
                                    One-Time Half Year Subscription – Rs.5310/- (incl. of 18% GST)
                                    Validity – 6 Months
                                </p>
                                <p>
                                    <span style={{ fontWeight: '600' }}>Target:</span><br></br>
                                    Must introduce at least 1 AajuBaajuian every month in the 1st 4 months from the date of registration.
                                </p>
                                <p>
                                    <span style={{ fontWeight: '600' }}>Withdrawal Eligibility:</span><br></br>
                                    Eligible to withdraw the Ad View earnings upon meeting the target of introducing AajuBaajuians as mentioned below…<br></br>
                                    Must have introduced 1 AajuBaajuian to withdraw the Ad View earnings in the 2nd Month.<br></br>
                                    Must have introduced 2 AajuBaajuians to withdraw the Ad View earnings in the 3rd Month.<br></br>
                                    Must have introduced 3 AajuBaajuians to withdraw the Ad View earnings in the 4th Month.<br></br>
                                    Must have introduced 4 AajuBaajuians to withdraw the Ad View earnings in the 5th Month.
                                </p>
                                <p>
                                    <span style={{ fontWeight: '600' }}>Note:</span><br></br>
                                    This is a one-time subscription and the renewal will be in Target Based Annual Subscription.
                                    If the AajuBaajuian count reduces below 4 the Ad View earning withdrawal will stop until the count becomes 4 again. There should always be 4 Active AajuBaajuians count to retain the Ad View earning withdrawal eligibility. Introducing more than 4 AajuBaajuians is optional. However, the more you introduce the more you earn. There is no upper limit to introduce AajuBaajuians.
                                </p>
                            </div><br></br>
                            {/* <p><strong>ONLY FOR THE AAJUBAAJUIANS IN THE TARGET SUBSCRIPTION MODEL</strong></p>
                            <p>AajuBaajuians in Beginner Stage and in Intermediate Stage for more than 4 months from the date of registration will not be eligible to withdraw Ad View earnings. The AajuBaajuians can keep earning by watching advertisements, however, the Ad View earnings will get accumulated. The accumulated Ad View earnings can be withdrawn when they get promoted from Beginner to Intermediate and Intermediate to Master respectively.</p> */}
                        </li>
                        <hr className={FooterStyle.line}></hr>
                        <hr className={FooterStyle.line}></hr>
                    </ul>
                </div>
                <div className="listings_footer row">
                    <div className={`${styles.large_rect}`}>
                        <LargeRectangle spot={"-1"} />
                    </div>
                    <div className={`${styles.billBoard}`}>
                        <BillBoard spot={"-1"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

