import React, { useEffect } from 'react';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'
import { ButtonStyle } from '../UIElements/Buttons/ButtonStyle';

export const VideosGuidelines = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="grid_container terms_of_service">
            <div className="row">
                <div className="col-md-12">
                    <ol type="I" className={FooterStyle.margin}>
                        <h2 style={{'margin-top': '40px'}}>General Guidelines:</h2>
                        <ol type="1">
                            <li>
                                <p>Should provide the download source of the video clips or images used in the videos taken from other sources.</p>
                            </li>
                            <li>
                                <p>Should use only the video clips or images which are allowed to use for commercial purpose.</p>
                            </li>
                        </ol>
                        <h2>Niche Level Guidelines for AajuBaaju My Videos:</h2>
                        <li>
                            <h2>Recipe Videos:</h2>
                            <ol type="1">
                                <li>
                                    <p>AajuBaajuian's introduction about the recipe. AajuBaajuian's sign-off holding the cooked food.</p>
                                </li>
                                <li>
                                    <p>AajuBaajuian's voice over explaining the cooking process.</p>
                                </li>
                                <li>
                                    <p>In case of someone else doing the cooking part, a video clip showing the AajuBaajuian's presence in that kitchen.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h2>Craft Videos:</h2>
                            <ol type="1">

                                <li>
                                    <p>AajuBaajuian's introduction about the craft. AajuBaajuian's sign-off holding the finished craft item.</p>
                                </li>
                                <li>
                                    <p>AajuBaajuian's voice over explaining the crafting process.</p>
                                </li>
                                <li>
                                    <p>In case of someone else doing the craft, a video clip showing the AajuBaajuian's presence while doing the craft.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h2>App Videos:</h2>
                            <ol type="1">

                                <li>
                                    <p>AajuBaajuian's introduction about the app.</p>
                                </li>
                                <li>
                                    <p>AajuBaajuian's embedded video clip explaining the application process.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h2>Game Role Play Videos:</h2>
                            <ol type="1">

                                <li>
                                    <p>AajuBaajuian's introduction about the game.</p>
                                </li>
                                <li>
                                    <p>AajuBaajuian's embedded video clip playing the game and explaining the gaming activities.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h2>Tour Videos:</h2>
                            <ol type="1">

                                <li>
                                    <p>AajuBaajuian's introduction about the tour from the location. AajuBaajuian's sign-off from the location.</p>
                                </li>
                                <li>
                                    <p>AajuBaajuian's presence throughout the video.</p>
                                </li>
                            </ol>
                        </li>

                        <li>
                            <h2>IMPORTANT NOTE:</h2>
                            <p>We will be adding/updating the guidelines as and when we come across a need, based on the user behaviour. It is every AajuBaajuian's reponsibility to check the niche guidelines every time before they create a video content.</p>
                        </li>
                    </ol>
                    <div className='row' style={{paddingBottom:'5px'}}>
                        <div className='col-md-9'></div>
                        <div className="col-md-3 add_button animated_back_button">
                            <ButtonStyle type="button" className="form-control btn btn-block btn_font"
                                handleClick={() => props.history.goBack()}>&nbsp; &laquo; &nbsp;Go Back </ButtonStyle>
                        </div>
                    </div>
                </div>
                <div className="listings_footer row">
                    <div className={`${styles.large_rect}`}>
                        <LargeRectangle spot={"-1"} />
                    </div>
                    <div className={`${styles.billBoard}`}>
                        <BillBoard spot={"-1"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

