import React, { useEffect, useRef } from 'react'
import dateFormator from '../../utility/dateFormator';
import TextDecoration from '../../utility/TextDecoration';
import { Ratings } from '../reviews/Ratings';
import VizSensor from 'react-visibility-sensor';
import listingService from '../service/listingService';
import styles from './card.module.css'
import { useViewport } from '../../utility/useViewport';

export const VideoVCard = (props) => {
    const listing = props.listing;
    const thumb = listing.thumbnailImage ? listing.thumbnailImage : 'assets/Desert.jpg'
    const isMobile = useViewport()

    let isViewCounted = false;
    const timeOut = useRef(null);
    /**const runTimer = (infocus) => {
        if (!isViewCounted && infocus)
            timeOut.current = setTimeout(() => countView(), 5000);
        else if (!infocus && timeOut.current)
            clearTimeout(timeOut.current)
    }**/
    const handleClick = () => {
        !isViewCounted && countView();
        props.showContent(listing, props.section)
    }
    const countView = () => {
        timeOut.current && clearTimeout(timeOut.current)
        isViewCounted = true;
        listingService.updateSingleListingViews(props.section, listing);
    }
    useEffect(() => {
        return () => timeOut.current && clearTimeout(timeOut.current)
    }, [timeOut])

    return (
        <VizSensor
        /**onChange={(isVisible) => {
            runTimer(isVisible)
        }}**/
        >
            {listing.listingType === 'article' ?
                <div className="col-cm-2 low_padding">
                    <div className={styles.listing_card_video} onClick={handleClick}>
                        <div className={styles.listing_thumb_image_video}>
                            <img className="img-fluid" loading='lazy' src={process.env.REACT_APP_IMAGES_URL + thumb} height='200' width='300' alt={listing.businessName} />
                        </div>
                        <div className="listing_details" style={{ padding: '15px' }}>
                            <h6 className={isMobile ? styles.mob_listing_title_video : styles.listing_title_video}>{TextDecoration.ellipsize(listing.businessName, 65)}</h6>
                            <div className="listing_address">
                                <div className={styles.listing_info_box_desc_newsArt_video}> {TextDecoration.ellipsize(listing.specializedIn, 130)}</div>
                                <div className={styles.basicVideoSubCat}>
                                    <span className={`${styles.name_span}`}>By:</span> {TextDecoration.ellipsize(listing.subCategories, 45)}
                                </div>
                            </div>
                            <div className="listing_address">
                                {(props.isViewed) ? <i className="fas fa-check-circle viewed_mark"></i> : null}
                            </div>
                        </div>
                    </div>
                </div> : listing.listingType === 'clips' ?
                    <div className="col-cm-4 low_padding">
                        <div className="clip_card" onClick={handleClick}>
                            <div>
                                <img className="img-fluid" loading='lazy' src={process.env.REACT_APP_IMAGES_URL + thumb} alt={listing.businessName} />

                            </div>
                            <div className="clip_description text-left">
                                <div className="description">
                                    <h6 className={isMobile ? styles.mob_listing_title_video : styles.clip_title_video}>{TextDecoration.ellipsize(listing.businessName, 50)}</h6>
                                    <div className={styles.basicVideoSubCatClip} style={{color: '#fff'}}>
                                        <span className={`${styles.name_span}`}>By:</span> {TextDecoration.ellipsize(listing.subCategories, 45)}
                                    </div>
                                </div>
                            </div>
                            {(props.isViewed) ? <i className="fas fa-check-circle viewed_mark"></i> : null}
                        </div>
                    </div> : null}
        </VizSensor>
    )
}