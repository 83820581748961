import React, { useState, useEffect } from 'react';
import { ContentTab } from './ContentTab';

export const ContentTabs = (props) => {
    const [selectedTab, setSelectedTab] = useState(props.listingType);

    useEffect(() => {
        setSelectedTab(props.listingType);
    }, [props.listingType]);

    const handleTabClick = (id) => {
        setSelectedTab(id);
        props.onToggle(id);
    };

    return (
        <div className='container-fluid'>
            <div className='row d-none d-md-block'>
                <div className='col-md-12 text-center'>
                    <ul className="row nav justify-content-center" role="tablist" style={{backgroundColor:'#e7f3e6'}}>
                        <ContentTab onClick={handleTabClick} title={'wifi_home'} id={'all'} active={selectedTab === "all"} />
                        <ContentTab onClick={handleTabClick} title={'video_library'} id="videos" active={selectedTab === "videos"} />
                        <ContentTab onClick={handleTabClick} title={'autoplay'} id="clips" active={selectedTab === "clips"} />
                        <ContentTab onClick={handleTabClick} title={'photo_library'} id="photos" active={selectedTab === "photos"} />
                        <ContentTab onClick={handleTabClick} title={'photo_album'} id="album" active={selectedTab === "album"} />
                        <ContentTab onClick={handleTabClick} title={'auto_stories'} id="pages" active={selectedTab === "pages"} />
                        <ContentTab onClick={handleTabClick} title={'hotel_class'} id="reviews" active={selectedTab === "reviews"} />
                    </ul>
                </div>
            </div>

            <div className='row d-block d-md-none mt-2'>
                <div className='col-md-12 text-center'>
                    <ul className="row nav" role="tablist" style={{backgroundColor:'#e7f3e6'}}>
                        <ContentTab onClick={handleTabClick} title={'wifi_home'} id={'all'} active={selectedTab === "all"} />
                        <ContentTab onClick={handleTabClick} title={'video_library'} id="videos" active={selectedTab === "videos"} />
                        <ContentTab onClick={handleTabClick} title={'autoplay'} id="clips" active={selectedTab === "clips"} />
                        <ContentTab onClick={handleTabClick} title={'photo_library'} id="photos" active={selectedTab === "photos"} />
                        <ContentTab onClick={handleTabClick} title={'photo_album'} id="album" active={selectedTab === "album"} />
                        <ContentTab onClick={handleTabClick} title={'auto_stories'} id="pages" active={selectedTab === "pages"} />
                        <ContentTab onClick={handleTabClick} title={'hotel_class'} id="reviews" active={selectedTab === "reviews"} />
                    </ul>
                </div>
            </div>
        </div>
    );
};
