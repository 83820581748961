import React, { useEffect, useState } from 'react';
import { VideoCardData } from './VideoCardData';
import styles from './cover.module.css';

export const ClipCardRow = (props) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
        }, 100);
    }, []);

    return (
        <div className={`row g-3 ${styles.card_transition} ${loaded ? `${styles.show}` : ''}`}>
            {props.listings.map((listing) => (
                <div key={listing.hpId} className="col-6 col-md-4 col-lg-3 col-xl-2 p-3 m-0 m-md-4">
                    <VideoCardData
                        {...props}
                        key={listing.hpId}
                        listing={listing}
                        isViewed={props.viewedContentIds.includes(listing.hpId)}
                    />
                </div>
            ))}
        </div>
    );
};
