import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import authservice from '../service/authservice';
import axios from 'axios';
import styles from '../authentication.module.css';
import { RedirectLink } from '../../UIElements/Redirect_link/Redirect_link';
import { useViewport } from '../../utility/useViewport';
import { ButtonStyle } from '../../UIElements/Buttons/ButtonStyle';

export const Welcome = (props) => {

    window.scrollTo(0, 0); //while loading the component it will focus top
    const isMobile = useViewport();

    const [userDetails, setUserDetails] = useState({})
    if (authservice.getCurrentUser() !== null)
        props.history.push('/')

    const userId = useParams().userid;

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_URL + '/abmgr/auth/basic-details?userId=' + userId).then(response => {
            setUserDetails(response.data)
        }).catch(error => {
            console.log(error)
        })
    }, [])

    return (
        <div className={isMobile ? `${styles.RegistrationMob}` : `${styles.Registration}`}>
            <div className={`grid_container ${styles.classified_add} ${styles.post_section}`}>
                <div className={`${styles.reg_form_section}`}>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>Congratulation {userDetails.firstName} !</h2>
                            <h2>Your aajubaaju.in Account is Successfully Created.</h2>
                            <h1><Link to="/login/user"><ButtonStyle type="button" className="btn" text={'Proceed to Login'} /></Link></h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}