import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import BillBoard from '../../advertisements/BillBoard';
import LargeRectangle from '../../advertisements/LargeRectangle';
import { TitleHeading } from '../../UIElements/headings/TitleHeading';
import { CardsRow } from '../cards/CardsRow';
import listingService from '../service/listingService';
import { useViewportBillBoard } from '../../utility/useViewport';

export const SponsoredEventJob = (props) => {

    const coords = useSelector(state => state.current_coords);
    const [listings, setListings] = useState([]);
    const viewedListings = useSelector(state => state.viewedListings);
    const isBillboard = useViewportBillBoard();

    const getListingDetails = (listing) => {
        const searchCriteria = listingService.getListingSettings(listing, coords);
        const businessName = listing.businessName.replace(/[^\w\s^&]/gm, ' ').trim().replace(/[\s]/gm, '-')
        const listingPath = encodeURI(`/l/${businessName}/${listing.hpId}`);
        props.history.push(listingPath, {
            place: 'sponsored',
            isViewed: viewedListings.includes(listing.hpId),
            searchCriteria: searchCriteria,
        });
    }

    useEffect(() => {
        // if (!coords.latitude)
        //     return;

        listingService.getAllListingsByType(coords, props.listingType.toLowerCase()).then(data => {
            setListings(data.sort((a, b) => moment(a.scheduleDate, "DD-MM-YYYY").diff(moment(b.scheduleDate, "DD-MM-YYYY"))));
        })
    }, [coords])

    return (
        listings.length > 0 ?
            <div>
                <TitleHeading text={`Sponsored ${props.listingType} Listings`} />
                <CardsRow listings={listings}
                    showContent={getListingDetails}
                    viewedContentIds={viewedListings}
                    type={props.listingType.toLowerCase()}
                    section='sponsored' />
                <div className='row'>
                    {isBillboard ? <LargeRectangle spot={props.bannerAdSpot} />
                        : <BillBoard spot={props.bannerAdSpot} />}
                </div>
            </div>
            : <></>
    )
}