import React from 'react';

export const HomeTab = (props) => {
    const iconBgColor = props.active ? '#fffaf5' : null;
    const iconColor = props.active ? '#FF9933' : '#138808';
    const listingTab = {
        "business": 'Business',
        "event": 'Events',
        "job": 'Jobs',
        "news": 'Pages',
        "article": 'Videos',
        "reviews": 'Reviews',
        "photos": 'Photos',
      };
    return (
        <>
        <div className='manage_desktop'>
            <li style={{background: iconBgColor}} className="nav-item col-md">
                <a className="nav-link" data-toggle="tab" href={"#" + props.id} role="tab">
                    <span
                        className={`material-symbols-outlined`}
                        style={{ fontSize: '18px', color: iconColor }}
                        onClick={() => props.onClick(props.id)}
                    >
                        {props.title}
                    </span> <span onClick={() => props.onClick(props.id)} style={{ fontSize: '18px', color: iconColor }}>{listingTab[props.id]}</span>
                </a>
            </li>
            </div>
            <div className='manage_mobile'>
                <li style={{background: iconBgColor}} className="nav-item">
                    <a className="nav-link" data-toggle="tab" href={"#" + props.id} role="tab">
                        <span
                            className={`material-symbols-outlined`}
                            style={{ fontSize: '18px', color: iconColor}}
                            onClick={() => props.onClick(props.id)}
                        >
                            {props.title}
                        </span>
                    </a>
                </li>
            </div>
        </>
    );
};
