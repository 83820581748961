import React, { useEffect, useState } from 'react';
import { VideoCardData } from './VideoCardData';
import styles from './cover.module.css';

export const VideoCardRow = (props) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
        }, 100);
    }, []);
    
    return (
        <>
            <div className={`row g-3 ${styles.items_list} ${styles.card_transition} ${loaded ? `${styles.show}` : ''}`}>
                {props.listings.map(listing => (
                    <VideoCardData
                        {...props}
                        key={listing.hpId}
                        listing={listing}
                        isViewed={props.viewedContentIds.includes(listing.hpId)}
                    />
                ))}
            </div>
        </>
    );
};

