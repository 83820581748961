import React, { useState } from 'react'
import axios from 'axios'
import { container, notifyUser, type } from '../../utility/notifyUser';
import styles from '../authentication.module.css';
import { useViewport } from '../../utility/useViewport';

export const ForgotPassword = () => {

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [processing, setProcessing] = useState(false);
    const isMobile = useViewport();


    const handleSubmit = (event) => {
        event.preventDefault();
        setProcessing(true);
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/auth/forgotpassword?username=${username}&email=${email}`)
            .then(response => {
                setProcessing(false);
                setUsername('')
                setEmail('')
                if (response.data)
                    notifyUser('', response.data, type.SUCCESS, container.BOTTOM_RIGHT);
            }).catch(error => {
                setProcessing(false);
                if (error.response && error.response.data)
                    notifyUser('', error.response.data, type.DANGER, container.BOTTOM_RIGHT)
                else
                    notifyUser('', 'Something is not right', type.WARNING, container.BOTTOM_RIGHT)
            })
    }
    return (
        <div className={isMobile ? `${styles.RegistrationMob} low_padding` : `${styles.Registration} low_padding`}>
            < div className={`grid_container ${styles.classified_add}`}>
                <form onSubmit={handleSubmit}>
                    <h2 className={`${styles.registration_header}`}>Retrieve Password Form</h2>
                    <div className={`${styles.reg_form_section}`}>
                        <div className="form-group row">
                            <div className="col-md-3"> <label className={`${styles.reg_form_section_label}`} htmlFor="title">User name</label></div>
                            <div className="col-md-9">
                                <input type="text" className="form-control" placeholder="User name" name="username"
                                    required onChange={event => setUsername(event.target.value)} noValidate />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-3">
                                <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Email</label></div>
                            <div className="col-md-9">
                                <input type="email" className="form-control" placeholder="Email" name="password"
                                    required onChange={event => setEmail(event.target.value)} noValidate />
                            </div>
                        </div>
                        <div className={`row ${styles.reg_button}`}>
                            <div className="col-md-12 text-center">
                                <button type="submit" className={`btn btn-warning ${styles.contact_subbtn} ${styles.submit_btn}`}>
                                    {processing ? <i class="fas fa-circle-notch fa-spin"></i> : 'Submit'}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div >
    )
}