import React, { useEffect, useRef } from 'react'
import dateFormator from '../../utility/dateFormator';
import TextDecoration from '../../utility/TextDecoration';
import { Ratings } from '../reviews/Ratings';
import VizSensor from 'react-visibility-sensor';
import listingService from '../service/listingService';
import styles from './card.module.css'

export const HCard = (props) => {
    const listing = props.listing;
    const section = props.section ? props.section : null;
    const thumb = listing.thumbnailImage ? listing.thumbnailImage : 'assets/Desert.jpg'
    const thumbnailDescriptionLength = listing.reviewDescription ? 180 : 250;

    let isViewCounted = false;
    const timeOut = useRef(null);
    /***const runTimer = (infocus) => {
        if (!isViewCounted && infocus)
            timeOut.current = setTimeout(() => countView(), 5000);
        else if (!infocus && timeOut.current)
            clearTimeout(timeOut.current)
    }***/
    const handleClick = () => {
        !isViewCounted && countView();
        props.showContent(listing, section)
    }
    const countView = () => {
        timeOut.current && clearTimeout(timeOut.current)
        isViewCounted = true;
        listingService.updateSingleListingViews(section, listing);
    }
    useEffect(() => {
        return () => timeOut.current && clearTimeout(timeOut.current)
    }, [timeOut])

    return (
        <VizSensor
        /**onChange={(isVisible) => {
            runTimer(isVisible)
        }}**/
        >
            <div className="row form-row listing_info_box curser_hand" onClick={handleClick}>
                <div className="col-md-3 no_padding">
                    {props.type === 'article' ?
                        <div className="newsArt_thumb_image_vid">
                            <img alt="Uploaded Pic" className="img-fluid" loading='lazy' src={process.env.REACT_APP_IMAGES_URL + thumb} /> :
                        </div> :
                        <div className="newsArt_thumb_image">
                            <img alt="Uploaded Pic" className="img-fluid" loading='lazy' src={process.env.REACT_APP_IMAGES_URL + thumb} />
                        </div>}
                    {props.type !== 'news' && props.type !== 'reviews' && props.type !== 'photos' && props.type !== 'album' && props.type !== 'article' ?
                        <div className="lookup-thumb-description text-left">
                            <p className="description">{listing.thumbnailDescription ? TextDecoration.ellipsize(listing.thumbnailDescription, thumbnailDescriptionLength) : ''}</p>
                            {listing.reviewDescription ?
                                <h6 className="customer_header">A Customer Said:</h6> : null}
                            <p className="review_desc">{TextDecoration.ellipsize(listing.reviewDescription, 120)}</p>
                        </div> : null}
                </div>
                <div className="col-md-9 newsArt_Desc">
                    {props.type === 'article' ?
                        <div className='video_card_desc'>
                            <h1 className="newsArt_link" >{TextDecoration.ellipsize(listing.businessName, 60)}</h1>
                            <div className="listing_info_box_desc_newsArt"> {TextDecoration.ellipsize(listing.specializedIn, 550)}
                                {(props.isViewed) ?
                                    <i className="fas fa-check-circle viewed_mark"></i> : null
                                }
                            </div>
                        </div> :
                        props.type === 'news' || props.type === 'reviews' || props.type === 'photos' || props.type === 'album' ?
                            <>
                                <h1 className="newsArt_link" >{TextDecoration.ellipsize(listing.businessName, 60)}</h1>
                                <div className="listing_info_box_desc_newsArt"> {TextDecoration.ellipsize(listing.specializedIn, 550)}
                                    {(props.isViewed) ?
                                        <i className="fas fa-check-circle viewed_mark"></i> : null
                                    }
                                </div>
                            </> :
                            <>
                                <div className="row form-row inner_row">
                                    <div className="col-md-8">
                                        <div className="listing_box_headcaption">
                                            <h1 className="newsArt_link">{TextDecoration.ellipsize(listing.businessName, 60)}</h1>
                                            <div className="listing_info_box_desc">
                                                {listing.listingType === 'job' ?
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label>Experience:</label>
                                                            {listing.experience ? " " + listing.experience : " Not Mentioned"}
                                                        </div>
                                                        <div className="col-md-6"><label>Salary:</label>{listing.salary ? " " + listing.salary : " Not Mentioned"}</div></div>
                                                    : null}

                                                <div className="rdw-storybook-root">
                                                    {listing.listingType === 'job'
                                                        ? <div className="listing_info_box_desc_newsArt">{TextDecoration.ellipsize(listing.specializedIn, 145)}</div>
                                                        : <div className="listing_info_box_desc_newsArt">{TextDecoration.ellipsize(listing.specializedIn, 255)}</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="info_addbox">
                                            {listing.scheduleDate ?
                                                <div className="addbox_icon" data-tooltip={dateFormator.scheduleDate(listing.scheduleDate)}><span
                                                    className=""><i className="fa fa-calendar" aria-hidden="true"></i></span> {dateFormator.scheduleDate(listing.scheduleDate)}
                                                </div> : null}
                                            {listing.firstScheduleTime ?
                                                <div className="addbox_icon" ><span
                                                    className=""><i className="fa fa-clock-o" aria-hidden="true"></i></span> {listing.firstScheduleTime}
                                                </div> : null}
                                            {listing.businessAddress ?
                                                <div className="addbox_icon" data-tooltip={listing.businessAddress}><span
                                                    className=""><i className="fa fa-map-marker"
                                                        aria-hidden="true"></i></span> {listing.businessAddress}
                                                </div> : null}
                                            {listing.emailAddress ?
                                                <div className="addbox_icon" data-tooltip={listing.emailAddress}><span className=""><i className="fa fa-envelope"
                                                    aria-hidden="true"></i></span> {listing.emailAddress}
                                                </div> : null}
                                            {listing.website ?
                                                <div className="addbox_icon" data-tooltip={listing.website}><span className=""><i className="fa fa-globe"
                                                    aria-hidden="true"></i></span> {listing.website}
                                                </div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div className="row rate_phone_person_box">
                                    {props.type === 'business' ? <div className="col-md-4">
                                        <Ratings totalRatings={listing.totalRatings} totalReviews={listing.totalReviews} />
                                    </div> : null}
                                    {listing.contactNumber ?
                                        <div className="col-md-3">
                                            <div className="person_number"><span className=""><i className="fa fa-phone-square" aria-hidden="true"></i></span> {listing.contactNumber}</div>
                                        </div> : null}
                                    {listing.contactPerson ?
                                        <div className="col-md-4">
                                            <div className="user_name"><span><i className="fa fa-user" aria-hidden="true"></i>
                                            </span> {listing.contactPerson}</div>
                                        </div> : null}
                                    {(props.isViewed) ?
                                        <div className="col-md-1">
                                            <i className={`fas fa-check-circle viewed_mark ${styles.viewed_mark}${styles.viewed_right}`}></i>
                                        </div> : null
                                    }
                                </div>
                            </>}
                </div>
            </div>
        </VizSensor>
    )
}