import React, { useEffect } from 'react';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'
import { ButtonStyle } from '../UIElements/Buttons/ButtonStyle';

export const PhotosGuidlines = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="grid_container terms_of_service">
            <div className="row">
                <div className="col-md-12">
                    <ol type="I" className={FooterStyle.margin}>
                        <h2 style={{ 'margin-top': '40px' }}>General Guidelines:</h2>
                        <ol type="1">
                            <li><p>AajuBaaju My photos section is not a personal photo album.</p></li>
                            <li>
                                <p>AajuBaajuian presence is mandatory in a minimum of 10 photos.</p>
                            </li>
                            <li>
                                <p>AajuBaajuian should not be the main focus in any of the photos.</p>
                            </li>
                            <li>
                                <p>All photos should be in a sequence and in the same dress.</p>
                            </li>
                        </ol>
                        <h2>Niche Level Guidelines for AajuBaaju My Photos:</h2>
                        <li>
                            <h2>Tour Photos:</h2>
                            <ol type="1">

                                <li>
                                    <p>Photo story description should contain details of the trip, date and place.</p></li>
                                <li>
                                    <p>AajuBaajuian should not hide any details or feature of the information, the photo should convey.</p>
                                </li>
                                <li>
                                    <p>There should be a tagline for every photo to explain the story of the photo.</p>
                                </li>
                                <li>
                                    <p>Group photos and self posing photos will be allowed only if there are more than 10 photos meeting the above guidelines.</p>
                                </li>
                                <li>
                                    <p>Multiple photo stories for different days of a single vaction/trip should not be created if the number of photos in the first listing is less than 50.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h2>Event Photos:</h2>
                            <ol type="1">

                                <li>
                                    <p>Photo story description should contain details of the event, date and place.</p>
                                </li>
                                <li>
                                    <p>The photos should be in a sequence with the tagline specifying the activity in the event.</p>
                                </li>
                                <li>
                                    <p>In case of group photos, AajuBaajuian should be prominently visible.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h2>Product Photos:</h2>
                            <ol type="1">

                                <li>
                                    <p>A business listing has to be added before adding a listing for product photos.</p>
                                </li>
                                <li>
                                    <p>Photo story description should have the business listing link and a brief about the photos.</p>
                                </li>
                                <li>
                                    <p>All photos should be taken by the AajuBaajuian.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h2>TEMPLE PHOTOS:</h2>
                            <ol type="1">

                                <li>
                                    <p>The photo story description should have details about the historical significance or sthala purana of the temple.</p>
                                </li>
                                <li>
                                    <p>Should have the address and temple timings.</p>
                                </li>
                                <li>
                                    <p>Tagline should describe the location and not the activity of the AajuBaajuian.</p>
                                </li>
                                <li>
                                    <p>Minimum 10 photos should be inside the temple.  Way to temple, surroundings of the temple photos can be added only when there are more than 10 photos.</p>
                                </li>
                            </ol>
                        </li>

                        <li>
                            <h2>IMPORTANT NOTE:</h2>
                            <p>We will be adding/updating the guidelines as and when we come across a need, based on the user behaviour. It is every AajuBaajuian's reponsibility to check the niche guidelines every time before they create a Photo Story.</p>
                        </li>
                    </ol>
                    <div className='row' style={{ paddingBottom: '5px' }}>
                        <div className='col-md-9'></div>
                        <div className="col-md-3 add_button animated_back_button">
                            <ButtonStyle type="button" className="form-control btn btn-block btn_font"
                                handleClick={() => props.history.goBack()}>&nbsp; &laquo; &nbsp;Go Back </ButtonStyle>
                        </div>
                    </div>
                </div>
                <div className="listings_footer row">
                    <div className={`${styles.large_rect}`}>
                        <LargeRectangle spot={"-1"} />
                    </div>
                    <div className={`${styles.billBoard}`}>
                        <BillBoard spot={"-1"} />
                    </div>
                </div>
            </div>
        </div>
    )
}