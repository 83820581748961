import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import authservice from '../service/authservice';
import { container, type, notifyUser } from '../../utility/notifyUser';
import adService from '../../advertisements/service/adService';
import styles from '../authentication.module.css';
import { ButtonStyle } from '../../UIElements/Buttons/ButtonStyle';
import Upgrade from '../../Upgrade';
import { message } from 'antd';

export const Login = (props) => {

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [varifying, setVerifying] = useState(false);
    const [expiryInfo, setExpiryInfo] = useState(null);
    const [showPassword, setShowPassword] = useState(false);


    const dispatch = useDispatch()

    useEffect(() => {
        window.scrollTo(0, 0); //while loading the component it will focus top
        if (authservice.getCurrentUser() !== null)
            props.history.push('/')
    }, [])

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        setVerifying(true)
        axios.post(process.env.REACT_APP_BACKEND_URL + '/abmgr/auth/login', { userName: userName.trim(), password: password }).then(response => {
            //if (response.data.status === 'OTP_VERIFICATION')
            // props.history.push({
            //     pathname: `/otp/${response.data.id}`,
            //     search: `?auth_mod=${response.data.mobileNumber}`,
            //     state: { email: response.data.email }
            // })
            // else if (response.data.status === 'EMAIL_VERIFICATION') {
            //     props.history.push({
            //         pathname: `/resendemail/${response.data.id}`,
            //         search: `?auth_mod=${response.data.email}`
            //     })
            // } 
            //else {
                // if (response.data.id)
                //     axios.post(process.env.REACT_APP_BACKEND_URL + "/abmgr/auth/logout?userId=" + response.data.id).catch(err => {
                //         console.log(err)
                //     })
                //localStorage.clear()

                const isAdmin = response.data.accountType === 'ADMIN' || response.data.accountType === 'SUPER_ADMIN'
                if (isAdmin) {
                    response.data.loginDate = new Date();
                    localStorage.setItem('user', JSON.stringify(response.data));
                    //dispatch({ type: 'IS_LOGIN', is_login: true })
                    dispatch({ type: 'ADMIN_LOGIN', isAdmin: isAdmin })
                    window.location.reload()
                    props.history.push('/admin-panel/dashboard')
                }
                if (response.data.id) {
                    axios.post(process.env.REACT_APP_BACKEND_URL + "/abmgr/auth/logout?userId=" + response.data.id).catch(err => {
                        console.log(err)
                    })
                    //     props.history.push({
                    //         pathname: `/login-otp/${response.data.id}`,
                    //         search: `?auth_mod=${response.data.mobileNumber}`,
                    //         userData: response.data
                    //     })
                    // }
                    response.data.loginDate = new Date();
                    localStorage.setItem('user', JSON.stringify(response.data));
                    dispatch({ type: 'IS_LOGIN', is_login: true })
                    //const isAdmin = response.data.accountType === 'ADMIN' || response.data.accountType === 'SUPER_ADMIN'
                    if (isAdmin) {
                        dispatch({ type: 'ADMIN_LOGIN', isAdmin: isAdmin })
                        props.history.push('/admin-panel/dashboard')
                    }
                    else {
                        const visitor = {
                            deviceId: authservice.getCookie('hp.rocks.soon'),
                            userId: response.data.id,
                        }
                        // props.history.push('/')
                        axios.post(process.env.REACT_APP_BACKEND_URL + "/abmgr/auth/visitor", visitor)
                        adService.getViewedAds(response.data).then(data => {
                            dispatch({ type: 'ALL_VIEWED_ADS', viewedAds: [...data] })
                            if (props.match.params.required === 'user')
                                props.history.push('/')
                            else
                                props.history.goBack();
                        })
                    }
                }
            //}
        }).then(null, error => {
            console.log(error.response)
            if (error.response.status == 403 && error.response.data && (error.response.data.message === 'ACOUNT HAS EXPIRED' || error.response.data.message === 'PREPAID' || error.response.data.message === 'MONTHLY_PREPAID' || error.response.data.message === 'HALF_YEARLY_PREPAID' || error.response.data.message === 'YEARLY_PREPAID')) {
                const userData = error.response.data;
                setExpiryInfo(userData);
            } else {
                switch (error.response.data) {
                    case 'FAILD_LOGIN_ATTEMPT_1':
                        notifyUser('Failed Attempt 1', 'Please try again. You have 3 attempts left', type.WARNING, container.BOTTOM_RIGHT);
                        break;
                    case 'FAILD_LOGIN_ATTEMPT_2':
                        notifyUser('Failed Attempt 2', 'Please try again. You have 2 attempts left', type.WARNING, container.BOTTOM_RIGHT);
                        break;
                    case 'FAILD_LOGIN_ATTEMPT_3':
                        notifyUser('Failed Attempt 3', 'Please try again. You have 1 attempt left', type.WARNING, container.BOTTOM_RIGHT);
                        break;
                    case 'LOCKED':
                        notifyUser('LOCKED', 'Account is Locked Due to Incorrect Password. Please Contact support@aajubaaju.in', type.INFO, container.BOTTOM_RIGHT);
                        break;
                    default:
                        notifyUser('Failed login', error.response.data, type.DANGER, container.BOTTOM_RIGHT);
                        break;
                }
            }
            setVerifying(false)
        }).catch(error => {
            console.log(error.response)
            notifyUser("", 'An unexpected error occured', "danger");
            setVerifying(false)
        });

    })

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            {expiryInfo !== null && <Upgrade user={expiryInfo} />}
            <div className={`${styles.Registration} low_padding`}>
                <div className={`grid_container ${styles.classified_add}`} >
                    <form onSubmit={handleSubmit}>
                        <h2 className={`${styles.registration_header}`}>Login</h2>
                        <div className={`${styles.reg_form_section}`}>
                            <div className="form-group row">
                                <div className="col-md-3"> <label className={`${styles.reg_form_section_label}`} htmlFor="title">User Name</label></div>
                                <div className="col-md-9">
                                    <input type="text" className="form-control" placeholder="User Name" name="username" required onChange={event => setUserName(event.target.value)} noValidate />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-3">
                                    <label className={`${styles.reg_form_section_label}`} htmlFor="desc">Password</label></div>
                                <div className="col-md-9">
                                    <input type={showPassword ? "text" : "password"} className="form-control" placeholder="Password" name="password" required onChange={event => setPassword(event.target.value)} noValidate></input>
                                    {showPassword ? <i
                                        className="password-toggle fas fa-eye-slash"
                                        onClick={togglePasswordVisibility}
                                    >
                                    </i> : <i
                                        className="password-toggle fas fa-eye"
                                        onClick={togglePasswordVisibility}
                                    >
                                    </i>}
                                </div>
                            </div>
                            <div className={`row ${styles.reg_button} log_button`}>
                                <div className="col-md-12 text-center">
                                    <ButtonStyle type="submit" className="btn">
                                        {varifying ? <i className="fas fa-circle-notch fa-spin"></i> : 'Login'}</ButtonStyle>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <Link to="/forgotpassword">Forgot Password?</Link>
                                </div>
                            </div>
                            {/* <div className={`row ${styles.account_register}`}>
                                <div className="col-md-12 text-center">
                                    <p>Do not have an account? <Link to="/registration">REGISTER</Link></p>
                                </div>
                            </div> */}
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}