import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TitleHeading } from '../UIElements/headings/TitleHeading';
import { useViewportBillBoard } from '../utility/useViewport';
import { VideoCardRow } from './VideoCardRow';
import listingService from '../listings/service/listingService';
import { ClipCardRow } from './ClipCardRow';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import styles from './cover.module.css';

export const VideoCard = (props) => {

    const coords = useSelector(state => state.current_coords);
    const [listings, setListings] = useState([]);
    const viewedListings = useSelector(state => state.viewedListings);
    const isBillboard = useViewportBillBoard();
    const [contentStatus, setContentStatus] = useState([]);

    const heading = props.listingType === 'news' ? 'Pages' : props.listingType === 'reviews' ? 'Reviews' : props.listingType === 'photos' ? 'Photos' : props.listingType === 'album' ? 'Album' : props.listingType === 'clips' ? 'Clips' : 'Videos'

    const getListingDetails = (listing) => {
        const searchCriteria = listingService.getListingSettings(listing, coords);
        const businessName = listing.businessName.replace(/[^\w\s^&]/gm, ' ').trim().replace(/[\s]/gm, '-')
        const listingPath = listing.listingType === 'article' ? encodeURI(`/l/${businessName}/${listing.hpId}`) : encodeURI(`/shareclips/${businessName}/${listing.hpId}`);
        props.history.push(listingPath, {
            isViewed: viewedListings.includes(listing.hpId),
            searchCriteria: searchCriteria,
        });
    }

    useEffect(() => {
        // if (!coords.latitude)
        //     return;

        listingService.getAllListingsContent(coords, props.listingType).then(data => {
            const sortedListings = data.sort((a, b) => moment(b.createdDate).diff(moment(a.createdDate)));
            setListings(sortedListings);
            setContentStatus(sortedListings.length > 0);

        })
    }, [coords])

    return (
        listings.length > 0 ?
            <>
                {props.listingType === 'article' ?

                    <div>
                        <TitleHeading text={`Recently Added ${heading}`} />
                        <VideoCardRow listings={listings}
                            showContent={getListingDetails}
                            viewedContentIds={viewedListings}
                            type={props.listingType}
                            contentStatus={true}
                        />
                        <div className={`d-flex my-3`}>
                            <Link className={styles.read_more_button} to={`/contents/${props.match.params.username}/videos`}>
                                Click here to See more...
                            </Link>
                        </div>

                    </div> :
                    <div>
                        <TitleHeading text={`Recently Added ${heading}`} />
                        <ClipCardRow listings={listings}
                            showContent={getListingDetails}
                            viewedContentIds={viewedListings}
                            type={props.listingType}
                        />
                        <div className="d-flex my-3">
                            <Link className={styles.read_more_button} to={`/contents/${props.match.params.username}/clips`}>
                                Click here to See more...
                            </Link>
                        </div>
                    </div>}
            </>
            : <></>
    )
}