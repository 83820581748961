import React, { useCallback, useState } from 'react'
import axios from 'axios';
import { container, notifyUser, type } from '../../utility/notifyUser';
import { Timer } from '../../utility/timer';
import { Link } from 'react-router-dom';
import styles from '../authentication.module.css';
import { useLocation } from 'react-router-dom';
import { mobilenumberRegex } from '../../RegxConst';
import TextDecoration from '../../utility/TextDecoration';
import { ButtonStyle } from '../../UIElements/Buttons/ButtonStyle';
import { useViewport } from '../../utility/useViewport';

export const OTP = (props) => {

    const [otp, setOtp] = useState('');
    const [newMobileNumber, setNewMobileNumber] = useState('');
    const [enableUpdateMobile, setEnableUpdateMobile] = useState(false);
    const [error, setError] = useState('');
    const [processing, setProcessing] = useState(false);
    const [sendingOtp, setSendingOtp] = useState(false);
    const [enableOtpResend, setEnableOtpResend] = useState(false);

    const userId = props.match.params.verify;
    const location = useLocation();
    const mobileNumber = location.search.split('=')[1];
    const isMobile = useViewport();

    const verifyOTP = event => {
        event.preventDefault();
        setProcessing(true);
        setError('')
        axios.post(process.env.REACT_APP_BACKEND_URL + `/abmgr/auth/otpvalidation/${userId}?otp=${otp}`)
            .then(response => {
                // if (response.data === 'EMAIL_VERIFICATION')
                //     props.history.push({
                //         pathname: `/resendemail/${userId}`,
                //         search: `?auth_mod=${location.state.email}`
                //     })
                // else 
                if (response.data === 'EXEPIRED')
                    notifyUser("", 'Entered OTP has Expired', type.INFO, container.BOTTOM_RIGHT)
                else if (response.data === 'ACTIVE')
                    props.history.push('/activation-success/' + userId)
                setProcessing(false);
            }).catch(error => {
                setProcessing(false);
                if (error.response)
                    notifyUser("", error.response.data, type.DANGER, container.BOTTOM_RIGHT)
            })
    }

    const resendOTP = () => {
        setSendingOtp(true)
        setEnableOtpResend(false)
        axios.get(process.env.REACT_APP_BACKEND_URL + '/abmgr/auth/resendotp/' + userId).then(response => {
            setSendingOtp(false)
            if (response.status == 200)
                notifyUser("OTP Sent!", "OTP is sent to your registered mobile number", type.SUCCESS, container.BOTTOM_RIGHT)
        }).catch(err => {
            setSendingOtp(false)
            setEnableOtpResend(true)
            if (err.response)
                notifyUser("OTP Error", "Something went wrong while sending OTP", type.DANGER, container.BOTTOM_RIGHT)
        })

    }

    const editMobileNumber = useCallback(() => {
        setError('')
        setEnableUpdateMobile((enableUpdateMobile) => !enableUpdateMobile)
        setNewMobileNumber('')
    }, [])

    const updateMobileNumber = useCallback((e) => {
        e.preventDefault();
        const error = mobilenumberRegex.test(newMobileNumber)
            ? (newMobileNumber.length < 10)
                ? 'Mobile Number Must Have 10 Digits'
                : ''
            : 'Invalid Mobile Number (Hint: Do not enter STD/ISO code)';
        setError(error)
        if (error)
            return

        setProcessing(true);

        const data = {
            userId: userId,
            mobileNumber: newMobileNumber,
            email: null,
        }
        axios.post(process.env.REACT_APP_BACKEND_URL + '/abmgr/auth/update-auth-info', data).then(response => {
            setProcessing(false);
            if (response.data) {
                notifyUser("Update Mobile", response.data, type.INFO, container.BOTTOM_RIGHT)
                props.history.push({
                    pathname: `/otp/${userId}`,
                    search: `?auth_mod=${newMobileNumber}`,
                    state: { email: location.state.email }
                })
                editMobileNumber();
            }
        }).catch(error => {
            setProcessing(false);
            if (error.response)
                notifyUser("Update Mobile", error.response.data, type.DANGER, container.BOTTOM_RIGHT)
        })
    }, [newMobileNumber])

    const enableResend = () => {
        setEnableOtpResend(true)
    }

    return (
        <div className={isMobile ? `${styles.RegistrationMob} low_padding` : `${styles.Registration} low_padding`}>
            <h2 className={`${styles.otp_header} ${styles.registration_header}`}>Validate Mobile Number</h2>
            <div className={`${styles.otp_form}`}>
                <form onSubmit={processing ? null : enableUpdateMobile ? updateMobileNumber : verifyOTP}>
                    <div className="form-row otp_section">
                        <div className="form-group col-md-12">
                            <label htmlFor="otp">
                                {enableUpdateMobile ? 'Mobile Number' :
                                    <>OTP Sent to <span className={`${styles.spacing}`}>{mobileNumber}</span></>}
                            </label>
                            &nbsp;&nbsp;
                            <button className={`btn btn-link ${styles.update_mobile}`} type='button'
                                onClick={editMobileNumber}>{enableUpdateMobile ? 'Cancel' : 'Not your number?'}</button>
                            {enableUpdateMobile &&
                                <><input type="text" className="form-control" placeholder="Enter New Mobile Number" value={newMobileNumber} name="otp"
                                    required onChange={event => setNewMobileNumber(event.target.value)} maxLength='10' />
                                    <span className='error'>{error}</span>
                                </>}
                        </div>
                        {!enableUpdateMobile &&
                            <div className="form-group col-md-12">
                                <label htmlFor="otp">Enter OTP</label>
                                <input type="text" className="form-control" placeholder="Enter OTP" value={otp} name="otp"
                                    required onChange={event => setOtp(event.target.value)} />
                                <span className='error'>{error}</span>
                            </div>
                        }
                        <div className="col-md-12 text-center">
                            <ButtonStyle type="submit" className={`btn ${styles.submit_btn}`}>
                                {processing ? <i className="fas fa-circle-notch fa-spin"></i> : 'Submit'}
                            </ButtonStyle>
                        </div>
                    </div>

                </form>
                {!enableUpdateMobile &&
                    <div className={`row ${styles.resend_otp}`}>
                        <div className="col-md-12 text-center">
                            {sendingOtp ? <i className="fas fa-circle-notch fa-spin"></i> :
                                <><button disabled={enableOtpResend ? false : true} type='button' className={`btn btn-link ${styles.resend_otp_btn}`} onClick={resendOTP}>Resend OTP</button>
                                    {enableOtpResend ? null : <Timer initialMinute={2} initialSeconds={0} onTimeUp={enableResend} />}</>}
                            <br />
                        </div>
                    </div>}
            </div>
        </div>
    )
}