import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import { connect } from 'react-redux'
import HeaderLeaderBoard from '../advertisements/HeaderLeaderBoard';
import Avatar from './Avatar';
import authservice from '../Authentication/service/authservice';
import styles from './header.module.css'
import adService from '../advertisements/service/adService';
import { ButtonStyle } from '../UIElements/Buttons/ButtonStyle';
import { LinkButton } from '../UIElements/Link_Button/LinkButton';
import listingService from '../listings/service/listingService';
import { notifyUser, type, container } from '../utility/notifyUser';
import ablogo from '../../assets/ablogo.svg'
import moblogo from '../../assets/moblogo.svg'

import moment from "moment"


class Header extends Component {

    constructor(props) {
        super(props);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.state = {
            totalPosts: 0,
            visitorsToday: 0,
            selfEarning: 0,
            adViewEarning: 0,
            referralEarning: 0,
            estimatedEarning: 0,
            referralKey: '',
            // position: this.calculateInitialPosition(),
            type: '',
            message: '',
            data: [],
            userLoggedIn: true,
        }
        this.cookieValue = '';
        this._isMounted = false;
    }

    getStripData = () => {
        axios.get(process.env.REACT_APP_BACKEND_URL + "/abmgr/auth/get-top-strip-data/" + this.cookieValue)
            .then(response => {
                this._isMounted && this.setState({ ...response.data })
            }).catch(e => {
                console.log(e);
            });
    }

    checkLoginSession = () => {
        const user = authservice.getCurrentUser();
        if (user && new Date(user.loginDate).getDate() !== new Date().getDate())
            this.logout();
        const date = new Date();
        if (moment(date).subtract(5, "m") === moment()) {
            notifyUser('INFO', 'You will be logged out automatically at 12 midnight. Please login again to continue working. If you are in middle of posting a listing click on "Submit for Approval" for free listing or "Save as Draft" for paid listing. You can go to "Manage Post" and continue working on the listing after 12 midnight.', type.INFO, container.CENTER);
        } else
            return '';
    }

    async validateGuestUser() {
        let cookieValue = authservice.getCookie('hp.rocks.soon')

        if (!cookieValue) {
            const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/abmgr/auth/check-ip");
            cookieValue = response.data
            if (!cookieValue)
                cookieValue = authservice.setCookie('hp.rocks.soon', this.generateGuestId());
            else
                authservice.setCookie('hp.rocks.soon', cookieValue);
        }
        this.saveVisitorTimeout = setTimeout(() => {
            const visitor = {
                deviceId: cookieValue,
                userId: authservice.getCurrentUserid(),
                latLon: this.props.current_coords.latitude + ',' + this.props.current_coords.longitude,
            }
            axios.post(process.env.REACT_APP_BACKEND_URL + "/abmgr/auth/visitor", visitor).catch(err => {
                if (err.response && err.response.status === 400) {
                    alert("Please allow cookies")
                    window.location.reload();
                }
            })
            clearTimeout(this.saveVisitorTimeout)
        }, 10000)
        const superInfo = authservice.getAccountType() === 'SUPER_ADMIN'
        const info = authservice.getAccountType() === 'ADMIN'
        if (info === false && superInfo === false) {
            this.getStripData();
            this.checkLoginSession();
            this.interval = setInterval(() => {
                this.getStripData();
                this.checkLoginSession();
            }, 300000);
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.validateGuestUser()
        // this.scrollText()
        // this.scrollMessage()
    }

    // calculateInitialPosition = () => {
    //     return window.innerWidth;
    // };

    // scrollText = () => {
    //     this.interval = setInterval(() => {
    //         const { position } = this.state;
    //         if (position <= -100) {
    //             this.setState({ position: window.innerWidth });
    //         } else {
    //             this.setState({ position: position - 1 });
    //         }
    //     }, 15);
    // };

    // scrollMessage = () => {
    //     axios.get(process.env.REACT_APP_BACKEND_URL + `/abmgr/notifications/get-notice-message?${this.props.is_login ? 'userId=' + authservice.getCurrentUserid() : 'guestId=' + authservice.getCookie('hp.rocks.soon')}`)
    //         .then((response) => {
    //             if (response.status === 200) {
    //                 this.setState({ data: response.data });
    //                 console.log(response.data)
    //             }
    //         })
    //         .catch((error) => {
    //             if (error.response && error.response.data) {
    //                 notifyUser("Fetch Data", "Error fetching data", type.DANGER);
    //             }
    //         });
    // }

    openModal = () => {
        this.setState({ open: true });
    }
    closeModal = () => {
        this.setState({ open: false });
    }

    generateGuestId = () => {
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.is_login !== this.props.is_login) {
            this.getEarningData();
            this.getReferralEarningData();
            this.earningDataUpdate = setInterval(() => {
                this.getEarningData();
            }, 900000);
        } else if (!prevProps.is_login && !this.props.is_login) {
            if (this.earningDataUpdate)
                clearInterval(this.earningDataUpdate)
        }
    }

    getEarningData = () => {
        if (authservice.getCurrentUserid() !== null)
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/abmgr/report/getTotalEarning/${authservice.getCurrentUserid()}`)
                .then(response => {
                    const e = response.data;
                    this._isMounted && this.setState({
                        selfEarning: e.selfTotal,
                        adViewEarning: e.adviewEarning,
                        estimatedEarning: e.estimatedEarning,
                    })
                }).catch(e => {
                    console.log(e);
                });
    }

    getReferralEarningData = () => {
        if (authservice.getCurrentUserid() !== null)
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/abmgr/report/get-total-loyality-earnings/${authservice.getCurrentUserid()}`)
                .then(response => {
                    const e = response.data;
                    this._isMounted && this.setState({
                        referralEarning: e.downLineEarning
                    })
                }).catch(e => {
                    console.log(e);
                });
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this._isMounted = false;
    }

    logout = () => {
        axios.post(process.env.REACT_APP_BACKEND_URL + "/abmgr/auth/logout?userId=" + authservice.getCurrentUserid())
            .then(resp => {
                this.props.history.push('/login/user')
            }).catch(err => {
                console.log(err)
            })
        authservice.logOut();
        adService.getGuestViewedAds(authservice.getCookie('hp.rocks.soon')).then(data => {
            this.props.sendViewedAds([...data])
        })
        listingService.getViewedListingIds().then(data => {
            this.props.setViewedListings(data)
        })
    }

    handleAddPost = (e) => {
        e.preventDefault();
        this.props.clearPropsData();
        this.props.history.push('/addpost')
    }

    handleAdvertise = (e) => {
        e.preventDefault();
        this.props.clearPropsData();
        this.props.history.push('/advertise')
    }

    render() {
        const location = encodeURI(window.location.origin + '/registration?ref=' + this.props.referralKey)
        const { position } = this.state;
        return (
            <>
                <div className={`d-custom-none ${styles.navbar_sticky}`} id="user_header" style={{ backgroundColor: '#fffaf5' }}>
                    <div className={`container-fluid ${styles.top_strip}`}>
                        <div className={`row align-items-center p-1`}>
                            {this.props.isTablet ?
                                <div className="col-md-3 col-12 text-md-left text-center">
                                    <span className={styles.visitor_count}>Visitors Today: <b>{this.state.visitorsToday + " "}</b>
                                        | Total Posts: <b>{(window.location.href.indexOf("/search") !== -1) ? this.props.listingCount : this.state.totalPosts}</b>
                                    </span>
                                </div> :
                                <div className="row col-12 align-items-center">
                                    <div className="col-md-4 col-12 text-md-left text-right">
                                        <span className={`pl-4 ${styles.contact_link}`}><Link to="/contact" className="social-icon text-xs-center">Contact Support Form</Link>{this.props.content_page === 'HomePage' ? null
                                            : <Link to="/" className="social-icon text-xs-center"> | Back to Home</Link>}</span>
                                    </div>
                                    <div className="col-md-5 col-12 text-md-center text-center">
                                        {this.props.is_login ?
                                            <b>
                                                Content: Rs.{this.state.selfEarning}/- &nbsp; Ad View: Rs.{this.state.adViewEarning}/- &nbsp;
                                                {authservice.getAccountType() === "REGISTERED" ?
                                                    <span><Link to={{
                                                        pathname: `/order/${authservice.getCurrentUserid()}`,
                                                        state: { payfor: 'membership' }
                                                    }} className={styles.upgrade_link}> UPGRADE</Link></span> : ' '}
                                                &nbsp;Loyalty: Rs.{this.state.referralEarning}/-
                                            </b> :
                                            <span className={`text-center ${styles.contact_link}`}>Welcome <b>Guest!</b> Earn while you browse. <Link to='/help'
                                                className={`social-icon text-xs-center ${styles.header_text}`}><b>Click here to know more</b></Link></span>}
                                    </div>
                                    <div className={`col-md-3 col-12 text-md-right text-right`}>
                                        <span>Visitors Today: <b>{this.state.visitorsToday + " "}</b>
                                            | Total Posts: <b>{(window.location.href.indexOf("/search") !== -1) ? this.props.listingCount : this.state.totalPosts}</b>
                                        </span>
                                    </div>
                                </div>}
                        </div>
                    </div>
                    {this.props.content_page === 'ClipPage' || this.props.content_page === 'ShareClipPage' ? null :

                        <div className="row col-md-12">
                            <div className="col-md-3">
                                <Link to="/">
                                    <img
                                        // className="img-fluid"
                                        src={ablogo}
                                        alt="Logo"
                                        height="90"
                                        width='360'
                                    />
                                </Link>
                            </div>
                            <div className="col-md-6 p-1 pl-5"><HeaderLeaderBoard /></div>

                            <div className="col-md-3 d-flex flex-column align-items-end">
                                <div className="row pt-2 w-100 justify-content-end pr-3">
                                    {this.props.is_login ? (
                                        <div className={`pr-2 ${styles.avatar}`}>
                                            <Avatar />
                                        </div>
                                    ) : null}
                                    {this.props.is_login ? (
                                        <div className='p-1'>
                                            <LinkButton handleClick={this.logout} text="Logout" />
                                        </div>
                                    ) : location && this.props.location.search !== '' && this.props.content_page !== 'ListingPage' ? null : (
                                        <div className='p-1'>
                                            <Link to="/login/user">
                                                <LinkButton text="Login" />
                                            </Link>
                                        </div>
                                    )}
                                    <div className='p-1'> &nbsp;|&nbsp; </div>
                                    {!this.props.is_login && this.props.content_page !== 'RegistrationPage' && (
                                        <>
                                            <div className='p-1'>
                                                <Link to="/registration">
                                                    <LinkButton text="Register" />
                                                </Link>
                                            </div>
                                            <div className='p-1'>&nbsp;|&nbsp;</div>
                                        </>
                                    )}

                                    <div className='p-1'>
                                        <Link to="/help">
                                            <LinkButton text="Help" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="row w-100 justify-content-end">
                                    <div className='p-1'>
                                        <ButtonStyle type="button" className="btn" text="Advertise" handleClick={this.handleAdvertise} />
                                    </div>
                                    <div className='p-1'>
                                        <ButtonStyle type="button" className="btn" text="Add Post" handleClick={this.handleAddPost} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="d-custom-block" id="user_header" style={{ backgroundColor: '#fffaf5' }}>
                    <div className={`container-fluid ${styles.top_strip}`}>
                        <div className={`row align-items-center p-1`}>
                            <div className="col-md-12 col-12 text-md-center text-center">
                                {this.props.is_login ?
                                    <b>
                                        Content: Rs.{this.state.selfEarning}/- &nbsp; Ad View: Rs.{this.state.adViewEarning}/- &nbsp;<br></br>
                                        {authservice.getAccountType() === "REGISTERED" ?
                                            <span><Link to={{
                                                pathname: `/order/${authservice.getCurrentUserid()}`,
                                                state: { payfor: 'membership' }
                                            }} className={styles.upgrade_link}> UPGRADE</Link></span> : ' '}
                                        &nbsp; Loyalty: Rs.{this.state.referralEarning}/- <br></br>
                                    </b> : null}
                                <span className={styles.visitor_count}>Visitors Today: <b>{this.state.visitorsToday + " "}</b>
                                    | Total Posts: <b>{(window.location.href.indexOf("/search") !== -1) ? this.props.listingCount : this.state.totalPosts}</b>
                                </span>
                            </div>

                        </div>
                    </div>
                    {this.props.content_page === 'ClipPage' || this.props.content_page === 'ShareClipPage' ? null :
                        <div className="row col-12 col-md-12">
                            <div className="col-3 col-md-3 float-left">
                                <Link to="/">
                                    <img
                                        src={moblogo}
                                        alt="Logo"
                                    />
                                </Link>
                            </div>
                            <div className="col-9 col-md-9 d-flex flex-column align-items-end">
                                <div className="row pt-2 w-100 justify-content-end pr-3">
                                    {this.props.is_login ? (
                                        <div className={`pr-2 ${styles.avatar}`}>
                                            <Avatar />
                                        </div>
                                    ) : null}
                                    {this.props.is_login ? (
                                        <div className='p-1'>
                                            <LinkButton handleClick={this.logout} text="Logout" />
                                        </div>
                                    ) : location && this.props.location.search !== '' && this.props.content_page !== 'ListingPage' ? null : (
                                        <div className='p-1'>
                                            <Link to="/login/user">
                                                <LinkButton text="Login" />
                                            </Link>
                                        </div>
                                    )}
                                    <div className='p-1'> &nbsp;|&nbsp; </div>
                                    {!this.props.is_login && this.props.content_page !== 'RegistrationPage' && (
                                        <>
                                            <div className='p-1'>
                                                <Link to="/registration">
                                                    <LinkButton text="Register" />
                                                </Link>
                                            </div>
                                            <div className='p-1'>&nbsp;|&nbsp;</div>
                                        </>
                                    )}
                                    <div className='p-1'>
                                        <Link to="/help">
                                            <LinkButton text="Help" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="row w-100 justify-content-end">
                                    <div className='p-1'>
                                        <ButtonStyle type="button" className="btn" text="Advertise" handleClick={this.handleAdvertise} />
                                    </div>
                                    <div className='p-1'>
                                        <ButtonStyle type="button" className="btn" text="Add Post" handleClick={this.handleAddPost} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>

            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        listingCount: (state.searching_counts.business + state.searching_counts.event + state.searching_counts.job + state.searching_counts.news + state.searching_counts.article),
        is_login: state.is_login,
        content_page: state.content_page,
        current_coords: state.current_coords,
        isAdmin: state.isAdmin,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        isLogin: (is_login) => dispatch({ type: 'IS_LOGIN', is_login: is_login }),
        clearPropsData: () => dispatch({ type: 'CLEAR_DATA' }),
        sendViewedAds: (viewedAds) => dispatch({ type: 'ALL_VIEWED_ADS', viewedAds: viewedAds }),
        setViewedListings: (listingIds) => dispatch({ type: 'VIEWED_LISTINGS', viewedListings: listingIds }),
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));