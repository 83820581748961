import React, { useEffect } from 'react';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'
import { Link } from 'react-router-dom';

export const ContentPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="grid_container terms_of_service">
            <div className="row">
                <div className="col-md-12">
                    <h1 className={`${FooterStyle.footer_links_header} text-center`}>USER GENERATED CONTENT POLICY</h1>
                    <p>Please read this User Generated Content Policy carefully before you submit User Generated Content to aajubaaju.in, as this policy and aajubaaju.in Terms &amp; Conditions will apply to your use of aajubaaju.in and the User Generated Content you submit to it or them. We recommend that you print a copy of this for future reference. By using aajubaaju.in and submitting User Generated Content to aajubaaju.in, you confirm that you accept this User Generated Content Policy, our Privacy and Cookie Policy and aajubaaju.in Terms & Conditions, and that you agree to comply with them. If you do not agree to these terms and policies, you must not use aajubaaju.in or submit User Generated Content to (or via) it (or them). Capitalised but undefined words in this User Generated Content Policy shall have the meanings ascribed to them in aajubaaju.in Terms &amp; Conditions.</p>
                    <ol type="I" className={FooterStyle.margin}>
                        <li>
                            <h2>YOUR UGC:</h2>
                            <ol type="1">
                                <li>
                                    <p>All content submitted to aajubaaju.in by you (or on your behalf) via your User account (or other social media account, if applicable), including without limitation, your name, biographical information and all other names, usernames, pseudonyms, text, likenesses, graphics, logos, marks, images, photographs, code, and all other information and material shall be called your "UGC" for short.</p>
                                </li>
                                <li>
                                    <p>You agree to submit UGC to aajubaaju.in in accordance with the following rules (in particular, the Legal Standards and the Review Guidelines, as those terms are defined below). Please use caution and common sense when submitting UGC to aajubaaju.in.</p>
                                </li>
                                <li>
                                    <p>Publication of your UGC will be at our sole discretion and we are entitled to make additions or deletions to your UGC prior to publication, after publication or to refuse publication.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h2>RIGHTS, PERMISSIONS &amp; WAIVERS:</h2>
                            <ol type="1">
                                <li>
                                    <p>You hereby grant to AajuBaaju eClassified Private Limited (t/a aajubaaju.in) and any of our group companies and affiliates a non-exclusive, perpetual, irrevocable, transferable, royalty-free licence (including full rights to sub-license) to use, reproduce and publish your UGC (including, without limitation, the right to adapt, alter, amend or change your UGC) in any media or format (whether known now or invented in the future) throughout the world without restriction, including commercial use.</p>
                                </li>
                                <li>
                                    <p>You warrant, represent and undertake to us that all UGC you submit is your own work or that you have obtained all necessary rights and permissions of the relevant owner of the work and that you have all relevant rights in your UGC to enable you to grant the rights and permissions in this clause II.</p>
                                </li>
                                <li>
                                    <p>Where your UGC contains images of people or names or identifies individuals, you warrant, represent and undertake to us as follows:</p>
                                    <ol type="a">
                                        <li>
                                            <p>that all featured or identified individuals that are over the age of 18 and have expressly consented to their appearance in the UGC and to you submitting the UGC to aajubaaju.in, and</p>
                                        </li>
                                        <li>
                                            <p>where featured or identified individuals are under the age of 18, that you either:</p>
                                            <ol type="i">
                                                <li>
                                                    <p>are the parent or legal guardian or such featured or identified individuals, or</p>
                                                </li>
                                                <li>
                                                    <p>have obtained the express consent from a parent or legal guardian of such featured or identified individuals to their appearance in the UGC and to you submitting the UGC to aajubaaju.in.</p>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>You hereby unconditionally and irrevocably waive and agree not to assert (or procure the same from any third party where applicable) any and all moral rights and any other similar rights and all right of publicity and privacy in any country in the world in connection with your UGC, to the maximum extent permissible by law.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h2>CONTENT STANDARDS – LEGAL STANDARDS:</h2>
                            <ol type="1">
                                <li>
                                    <p>You warrant, represent and undertake to us that your UGC (including its use, publication and/or exploitation by us) shall not:</p>
                                    <ol type="a">
                                        <li>
                                            <p>infringe the copyrights or database rights, trademarks, rights of privacy, publicity or other intellectual property or other rights of any other person or entity; and/or.</p>
                                        </li>
                                        <li>
                                            <p>contain any material which is defamatory of any person; and/or</p>
                                        </li>
                                        <li>
                                            <p>contain misleading or deceptive statements or omissions or misrepresentation as to your identity (for example, by impersonating another person) or your affiliation with any person or entity; and/or</p>
                                        </li>
                                        <li>
                                            <p>breach any legal or fiduciary duty owed to a third party, such as a contractual duty or a duty of confidence; and/or</p>
                                        </li>
                                        <li>
                                            <p>advocate, promote, or assist discrimination based on race, sex, religion, nationality, disability, sexual orientation or age; and/or</p>
                                        </li>
                                        <li>
                                            <p>contain any malicious code, such as viruses, worms, Trojan horses or other potentially harmful programmes, codes or material; and/or</p>
                                        </li>
                                        <li>
                                            <p>violate any other applicable law, statute, ordinance, rule or regulation, (together, or individually the "Legal Standards").</p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>If your UGC contains any material that is not owned by or licensed to you and/or which is subject to third party rights, you are responsible for obtaining, prior to submission of your UGC, all releases, consents and/or licenses necessary to permit use and exploitation of your UGC by us without additional compensation. Please see clause II above for further details.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h2>CONTENT STANDARDS – REVIEW GUIDELINES:</h2>
                            <ol type="1">
                                <li>
                                    <p>You warrant, represent and undertake to us that your UGC:</p>
                                    <ol type="a">
                                        <li>
                                            <p>is accurate, where it states facts; and/or</p>
                                        </li>
                                        <li>
                                            <p>is genuinely held, where it states opinions (for example, in product or services reviews).</p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>You further warrant, represent and undertake to us that your UGC (including its use, publication and/or exploitation by us) shall be compliant with our Review Guidelines, which can be found in clause VII.</p>
                                    <p>Any UCG which is in breach of our Review Guidelines or is otherwise:</p>
                                    <ol type="a">
                                        <li>
                                            <p>is obscene, hateful, inflammatory, offensive or in any other way falls below commonly accepted standards of taste and decency in INDIA; and/or</p>
                                        </li>
                                        <li>
                                            <p>is reasonably likely to harass, upset, embarrass or alarm a person (including, by way of example only, so called "trolling" or cyber-bullying); and/or</p>
                                        </li>
                                        <li>
                                            <p>is threatening, abusive or invades another's privacy, or causes annoyance, inconvenience or anxiety; and/or</p>
                                        </li>
                                        <li>
                                            <p>is sexually explicit; and/or</p>
                                        </li>
                                        <li>
                                            <p>advocates, promotes, assists or depicts violence; and/or</p>
                                        </li>
                                        <li>
                                            <p>advocates, promotes or assists any illegal activity or unlawful act or omission; and/or</p>
                                        </li>
                                        <li>
                                            <p>could be deemed to be unsolicited or unauthorised advertising, promotional material, junk mail, or spam (including without limitation chain letters, pyramid schemes or other forms of solicitation or advertisements, commercial or otherwise); and/or</p>
                                        </li>
                                        <li>
                                            <p>gives the impression that it emanates from aajubaaju.in or is endorsed or connected with us, if this is not the case, will be removed from aajubaaju.in.</p>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h2>CONSEQUENCES OF BREACH:</h2>
                            <ol type="1">
                                <li>
                                    <p>We will determine, in our discretion, whether you have failed to comply with this UGC Policy when submitting UGC to aajubaaju.in. If you have failed to comply, we reserve the right in our sole discretion to suspend you from using aajubaaju.in without notice to you and/or to edit or remove (in whole or part) any of your UGC aajubaaju.in on a temporary or permanent basis.</p>
                                </li>
                                <li>
                                    <p>Notwithstanding clause (V)1 above, if you or your UGC does not comply with this UGC Policy, and as a result of this, we suffer any loss or damage, you will be liable to us and hereby agree to indemnify us for any such loss or damage. This means that you will be responsible for any loss or damage we suffer as a result of your failure to comply with this UGC Policy, including but not limited to our Legal Standards and/or Review Guidelines.</p>
                                </li>
                                <li>
                                    <p>We also reserve the right:</p>
                                    <ol type="a">
                                        <li>
                                            <p>to pass on any UGC that gives us concern to the relevant authorities; and</p>
                                        </li>
                                        <li>
                                            <p>to disclose your identity to any third party (or their professional advisor) who claims that any of your UGC constitutes a violation of their intellectual property rights, or of their right to privacy.</p>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h2>CHANGES IN THE UGC POLICY:</h2>
                            <p>We may change this UGC Policy from time to time, in which case an up to date version will be available via aajubaaju.in. You should check this UGC Policy regularly to ensure that you are happy with any changes. You will be deemed to have accepted any changes to this UGC Policy after you have been notified of the changes on aajubaaju.in and/ or if you continue to access or use aajubaaju.in, where the updated UGC Policy will be available for you to view.</p>
                        </li>
                        <li>
                            <h2>REVIEW GUIDELINES:</h2>
                            <p>We think the aajubaaju.in review platform is pretty nifty. Reviews are a great way to find out everything you need to know without the sugar-coated sales talk. Of course, to keep things honest, clean and real, it helps if we’re all singing from the same sheet.</p>
                            <p>So, in the name of a healthy, positive review platform, here are our guidelines:</p>
                            <ol type="1">
                                <li>
                                    <p><strong>The good stuff:</strong></p>
                                    <p>How to get the best aajubaaju.in experience</p>
                                    <ol type="a">
                                        <li>
                                            <p>Get stuck in. Whatever you have to say, chances are there’s someone out there who will benefit from your personal first-hand experiences and opinions. Share them all—good or bad—and include as many details as you can. The sooner you leave a review, the more you will remember, so please try and leave it as soon as you can.</p>
                                        </li>
                                        <li>
                                            <p>Be constructive. If you had a bit of a rubbish experience, tell us why. Be specific about what it was that didn’t hit the spot and not only will other users take you more seriously, but the business or professional involved is much more likely to listen up and improve. The more detail you can provide in your review, the more helpful it will be to others - it’s fine to only use a few words, so long as they are constructive and helpful to other customers. If your review is difficult to make sense of or doesn’t help other customers, the content may be removed - but your star rating will still count towards the venues rating.</p>
                                        </li>
                                        <li>
                                            <p>Be courteous. We are a diverse community of people with diverse opinions. Speaking to and about people with respect means that everyone feels free to have their say.</p>
                                        </li>
                                        <li>
                                            <p>When you see it, call it. If you see abusive content or material that violates these guidelines, send an email to support@aajubaaju.in. We always try to monitor reviews that breach our guidelines but we definitely appreciate our customers letting us know if they spot something before we do. We may temporarily disable reviews where they have been reported so that our team can look into this properly.</p>
                                        </li>
                                        <li>
                                            <p>Your contributions are your voice on aajubaaju.in, so make sure those smooth, dulcet tones ring out by being as clear, descriptive and typo-free as possible.</p>
                                        </li>
                                        <li>
                                            <p>Stay safe. Be wise about the personal information you share when you’re using publicly accessible parts of the Platform—remember that this information may remain there indefinitely. aajubaaju.in will of course still know who you are, which means we can get in touch if we need to.</p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p><strong>The naughty stuff:</strong></p>
                                    <ol type="a">
                                        <li>
                                            <p>Stick to the facts. Please only write reviews based on your own personal experience and only submit content that you know to be accurate and fair. For example, whilst you might not be happy with the service you received & may think the person treating you wasn’t qualified to do so, you shouldn’t write that in your review if you don’t know for a fact that’s the case. Your review should also only focus on your own experience - please don’t write a review on behalf of anyone else, they will have their own chance to submit a review.</p>
                                        </li>
                                        <li>
                                            <p>Keep the peace. aajubaaju.in is not the place for threats, personal attacks, aggressive comments or hateful, bigoted or racist speech. We are a community of people with diverse beliefs, opinions and backgrounds, so please be respectful.</p>
                                        </li>
                                        <li>
                                            <p>Don’t talk dirty. We want to keep things clean, which means no swear words, no vulgar comments, no sexually explicit language or images and no lewd propositions. Also, please don’t write in CAPS—it may be big, but it’s not clever. Please note that if we do spot the use of CAPS these will be amended to lowercase.</p>
                                        </li>
                                        <li>
                                            <p>No spam. Any obvious advertising references found in reviews, comments or other content will be deleted immediately. It is not permitted to post links, HTML, provide phone numbers, email addresses or to provide information that is unrelated to the topic in order to solicit personal or financial gain. It’s also forbidden to send bulk messages or submit any other unauthorised content with the purpose of promoting your business or product.</p>
                                        </li>
                                        <li>
                                            <p>No cheating. Our reputation and creditsystem is there to recognize and reward citizens for writing reviews and contributing to the aajubaaju.in community.</p>
                                        </li>
                                        <li>
                                            <p>Keeping it legal. Make sure you don’t break the law by:</p>
                                            <ol type="none">
                                                <li>
                                                    <p>submitting fake reviews or paying someone to submit content that benefits your business</p>
                                                </li>
                                                <li>
                                                    <p>copying other people’s content without permission or proper attribution—please be aware of copyright laws</p>
                                                </li>
                                                <li>
                                                    <p>scamming people into sharing personal information</p>
                                                </li>
                                                <li>
                                                    <p>stealing or borrowing anything from aajubaaju.in or other sites (like copyright or trademark material)</p>
                                                </li>
                                                <li>
                                                    <p>threatening, defaming, making libellous statements, harassing, invading people’s privacy or impersonating others. Defamation or libel means that a review makes a false statement of fact which would then be damaging - this could include, for example, if you say in your review that a therapist is not qualified, as this goes beyond your experience and knowledge - please just stick to giving your opinion on your experience.</p>
                                                </li>
                                            </ol>
                                            <p>We are committed to doing everything we can to protect aajubaaju.in from fake reviews. On the rare occasion that we do find bogus content, spam, fake reviews or submissions from fraudulent profiles, we remove them immediately and take steps to ensure that the businesses and users in question are penalised in our rankings on aajubaaju.in</p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p><strong>When we have to step in:</strong></p>
                                    <p>aajubaaju.in reserves the right to edit or remove reviews that we determine to be inappropriate and/or in violation of these aajubaaju.in Review Guidelines or other terms of use.</p>
                                    <p>Please be aware that, depending on the level of offence, violating the Review Guidelines may result in the termination of your aajubaaju.in account without warning.</p>
                                    <p>If your review is reported to the Review Team for breach, they may (a) temporarily disable the review or (b) remove its written content while they investigate. If they determine that the review does breach our Review Guidelines, they may elect to (a) remove the review in its entirety or (b) remove its written content whilst still retaining the star rating of the review. The Review Team will always reinstate the written content of the review if you re-edit and re-submit a review which conforms with these Guidelines.</p>
                                    <p>Serious allegations &amp; Police Investigations</p>
                                    <p>Thankfully, it is rare that any serious allegations are made against any user or listings on aajubaaju.in, but in the very unfortunate event that criminal activity, police investigations, or serious allegations are made against a user or listing, please send an email to support@aajubaaju.in so that we can help in any way that we can and can give the issue all the attention that it needs. We can’t allow such serious matters to be recorded on a review platform, so in the event that you do leave a review referencing serious allegations or police investigations, we will remove the review and reach out to you privately so that we can assist in the best way possible.</p>
                                    <p>These review guidelines were written with the best interests of the aajubaaju.in community in mind. If you have any additional suggestions on how to improve them, or any other feedback, please send us an email to support@aajubaaju.in.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h2>BUSINESS LISTING POST GUIDELINES:</h2>
                            <p>AajuBaaju aims to provide a platform for even the small or tiny businesses with limited service areas to have an online presence.</p>
                            <p><strong>Let’s do this:</strong></p>
                            <ol type="1">
                                <li>
                                    <p>Contact the business owner or the authorized person, introduce aajubaaju.in and get the permission to list their business.</p>
                                </li>
                                <li>
                                    <p>Select the appropriate category and subcategory for the business. Add a new category or subcategory only when there is no appropriate category or subcategory available.</p>
                                </li>
                                <li>
                                    <p>Post only the businesses that will show in your location. Set the location details of the areas correctly in which the business want to display.</p>
                                </li>
                                <li>
                                    <p>Upload a thumbnail image in 425px by 425px dimension. The image in the thumbnail should be legible and good looking. The thumbnail image should strictly have either the photo of the business establishment with the business name clearly visible or inside photo of the business establishment with the authorized person prominently visible. Make sure the business name is not getting cropped in the thumbnail. The thumbnail photo should be taken by the AajuBaajuian.</p>
                                </li>
                                <li>
                                    <p>The specialized in section should be descriptive with minimum of 150 characters.</p>
                                </li>
                                <li>
                                    <p>Input the complete contact details accurately. Verify the contact number.</p>
                                </li>
                            </ol>

                            <p><strong>Let’s NOT do this:</strong></p>
                            <ol type="1">
                                <li>
                                    <p>Do not add any listing without the knowledge of the business.</p>
                                </li>
                                <li>
                                    <p>Do not select incorrect categories and subcategories which are irrelevant for the business.</p>
                                </li>
                                <li>
                                    <p>Do not add new category or subcategory without thoroughly researching the available categories or subcategories.</p>
                                </li>
                                <li>
                                    <p>Do not add multiple listing for one business with the same address.</p>
                                </li>
                                <li>
                                    <p>Do not post the businesses that will not show in your location.</p>
                                </li>
                                <li>
                                    <p>Do not set incorrect location where the business in not serving.</p>
                                </li>
                                <li>
                                    <p>Do not add any images copied from the internet for thumbnail.</p>
                                </li>
                                <li>
                                    <p>Do not add business cards, invoice, cash memos, business logos, any printed materials, etc., as thumbnail images.</p>
                                </li>
                                <li>
                                    <p>Do not add blurry, rotated and cropped images in the thumbnail.</p>
                                </li>
                                <li>
                                    <p>Do not add the listing with incomplete address.</p>
                                </li>
                                <li>
                                    <p>Do not add the listing with unverified contact details.</p>
                                </li>
                            </ol>

                            <p>All business listings posted are moderated by our content moderators’ team and any business listing not in compliance with our rules will be withdrawn forthwith. <Link to="/content-policy/business" className={FooterStyle.link}>Click here to see the detailed niche level guidelines</Link></p>
                        </li>
                        <li>
                            <h2>EVENT LISTING POST GUIDELINES:</h2>
                            <p>AajuBaaju aims to provide a platform to list even neighbourhood level events or personal events online.</p>
                            <p><strong>Let’s do this:</strong></p>
                            <ol type="1">
                                <li>
                                    <p>Contact the event organizer or the authorized person, introduce aajubaaju.in and get the permission to list their event.</p>
                                </li>
                                <li>
                                    <p>Select the appropriate category and subcategory for the event. Add a new category or subcategory only when there is no appropriate category or subcategory available.</p>
                                </li>
                                <li>
                                    <p>Post only the events that will show in your location. Set the location details of the areas correctly in which the organizer wants to announce the event.</p>
                                </li>
                                <li>
                                    <p>Upload a thumbnail image in 425px by 425px dimension. The image in the thumbnail should be legible and good looking. The thumbnail image should strictly have the event venue photo taken by the AajuBaajuian or images provided by the event organizer</p>
                                </li>
                                <li>
                                    <p>The event description section should be descriptive with minimum of 150 characters.</p>
                                </li>
                                <li>
                                    <p>Input the complete contact details accurately. Verify the contact number.</p>
                                </li>
                            </ol>

                            <p><strong>Let’s NOT do this:</strong></p>
                            <ol type="1">
                                <li>
                                    <p>Do not add any listing without the knowledge of the event organizer.</p>
                                </li>
                                <li>
                                    <p>Do not select incorrect categories and subcategories which are irrelevant for the event.</p>
                                </li>
                                <li>
                                    <p>Do not add new category or subcategory without thoroughly researching the available categories or subcategories.</p>
                                </li>
                                <li>
                                    <p>Do not add multiple listing for the same event.</p>
                                </li>
                                <li>
                                    <p>Do not post the events that will not show in your location.</p>
                                </li>
                                <li>
                                    <p>Do not set incorrect location where the event organizer does not want to announce.</p>
                                </li>
                                <li>
                                    <p>Do not add any images copied from the internet for thumbnail.</p>
                                </li>
                                <li>
                                    <p>Do not add blurry, rotated and cropped images in the thumbnail.</p>
                                </li>
                                <li>
                                    <p>Do not add the listing with incomplete address.</p>
                                </li>
                                <li>
                                    <p>Do not add the listing with unverified contact details.</p>
                                </li>
                            </ol>

                            <p>All event listings posted are moderated by our content moderators’ team and any event listing not in compliance with our rules will be withdrawn forthwith. <Link to="/content-policy/event" className={FooterStyle.link}>Click here to see the detailed niche level guidelines</Link></p>
                        </li>
                        <li>
                            <h2>JOB LISTING POST GUIDELINES:</h2>
                            <p>AajuBaaju aims to provide a platform for small or tiny businesses to list their job vacancies online.</p>
                            <p><strong>Let’s do this:</strong></p>
                            <ol type="1">
                                <li>
                                    <p>Contact the recruiter or the authorized person, introduce aajubaaju.in and get the permission to list their job.</p>
                                </li>
                                <li>
                                    <p>Select the appropriate category and subcategory for the job. Add a new category or subcategory only when there is no appropriate category or subcategory available.</p>
                                </li>
                                <li>
                                    <p>Post only the jobs that will show in your location. Set the location details of the areas correctly in which the recruiter want to post the job.</p>
                                </li>
                                <li>
                                    <p>Upload a thumbnail image in 425px by 425px dimension. The image in the thumbnail should be legible and good looking. The thumbnail image should strictly have the company photo taken by the AajuBaajuian or company logo or company name.</p>
                                </li>
                                <li>
                                    <p>Input the complete contact details accurately. Verify the contact number.</p>
                                </li>
                            </ol>

                            <p><strong>Let’s NOT do this:</strong></p>
                            <ol type="1">
                                <li>
                                    <p>Do not add any listing without the knowledge of the recruiter.</p>
                                </li>
                                <li>
                                    <p>Do not select incorrect categories and subcategories which are irrelevant for the job.</p>
                                </li>
                                <li>
                                    <p>Do not add new category or subcategory without thoroughly researching the available categories or subcategories.</p>
                                </li>
                                <li>
                                    <p>Do not add multiple listing for the same job.</p>
                                </li>
                                <li>
                                    <p>Do not post the jobs that will not show in your location.</p>
                                </li>
                                <li>
                                    <p>Do not set incorrect location where the recruiter does not want to post the job.</p>
                                </li>
                                <li>
                                    <p>Do not add any images copied from the internet for thumbnail.</p>
                                </li>
                                <li>
                                    <p>Do not add blurry, rotated and cropped images in the thumbnail.</p>
                                </li>
                                <li>
                                    <p>Do not add the listing with incomplete address.</p>
                                </li>
                                <li>
                                    <p>Do not add the listing with unverified contact details.</p>
                                </li>
                            </ol>

                            <p>All job listings posted are moderated by our content moderators’ team and any job listing not in compliance with our rules will be withdrawn forthwith. <Link to="/content-policy/job" className={FooterStyle.link}>Click here to see the detailed niche level guidelines</Link></p>
                        </li>
                        <li>
                            <h2>AAJUBAAJU MY PAGES GUIDELINES</h2>
                            <p>AajuBaaju provides a platform for the AajuBaajuians to share their knowledge, showcase their talents or exhibit their hobbies through AajuBaaju My Pages to people visiting the platform. The AajuBaajuians can post articles, neighbourhood positive news, niche blog writing, success stories, etc.</p>
                            <p><strong>Ideally, this AajuBaaju My Pages section is for the AajuBaajuians</strong></p>
                            <ol type="1">
                                <li>
                                    <p>who are bloggers, writers, influencers, news reporters, etc.;</p>
                                </li>
                                <li>
                                    <p>who have excellent writing skills.</p>
                                </li>
                            </ol>

                            <p><strong>Let’s do this:</strong></p>
                            <ol type="1">
                                <li>
                                    <p>Should post only under the AajuBaajuian’s own AajuBaaju My Pages name.</p>
                                </li>
                                <li>
                                    <p>Should be written in one’s own words and should have a minimum of 500 words.</p>
                                </li>
                                <li>
                                    <p> The recipes should have minimum of 3 photos in the content. One photo of the ingredients, one preparation photo and one cooked dish photo is mandatory.</p>
                                </li>
                                <li>
                                    <p>All articles should have a minimum of 2 photos. Photos should not be copied from the internet.</p>
                                </li>
                                <li>
                                    <p>Should write quality content which will be helpful for the society with good grammar and correct spellings.</p>
                                </li>
                                <li>
                                    <p>Upload a thumbnail image in 425px by 425px dimension. The image in the thumbnail should have the AajuBaaju My Page Name or a photograph of the AajuBaajuian.</p>
                                </li>
                                <li>
                                    <p>Image width used in the content should be minimum 600px and maximum 800px.</p>
                                </li>
                                <li>
                                    <p>Image height should always be minimum 100px lesser than image width. i.e., the image should be wider, not taller or square.</p>
                                </li>
                                <li>
                                    <p>Image file size can be maximum of 2MB.</p>
                                </li>
                                <li>
                                    <p>Images or photographs should be one’s own or one should have rights to use the images. Send an email to support along with the submissions, if image rights are purchased to be used in one’s content. Please check the copyright policy for details.</p>
                                </li>
                                <li>
                                    <p>The title and the description should be only in English.</p>
                                </li>
                                <li>
                                    <p>Should follow the compliance mentioned in the content policy.</p>
                                </li>

                            </ol>

                            <p><strong>Let’s NOT do this:</strong></p>
                            <ol type="1">
                                <li>
                                    <p>Should not post under other AajuBaajuian’s AajuBaaju My Pages name.</p>
                                </li>
                                <li>
                                    <p>Do not copy content belonging to others from the internet or from any other media.</p>
                                </li>
                                <li>
                                    <p>Do not copy partial content from multiple websites and create a page.</p>
                                </li>
                                <li>
                                    <p>Do not copy images belonging to others from the internet or from any other media.</p>
                                </li>
                                <li>
                                    <p>Do not partially edit the images copied from the internet to use in the page.</p>
                                </li>
                                <li>
                                    <p>Do use languages other than English in the title or description.</p>
                                </li>
                            </ol>


                            <p>All pages will be pre-moderated by our content moderators’ team and only those pages which are in compliance with our guidelines will be published. The AajuBaajuians will be updated through email about the status of their submissions. <Link to="/content-policy/aajubaaju-my-pages" className={FooterStyle.link}>Click here to see the detailed niche level guidelines</Link></p>
                        </li>
                        <li>
                            <h2>AAJUBAAJU MY VIDEOS GUIDELINES:</h2>
                            <p>AajuBaaju provides a platform for the AajuBaajuians to share their knowledge, showcase their talents or exhibit their hobbies through AajuBaaju My Videos to people visiting the platform. The AajuBaajuians can post video content.</p>
                            <p><strong>Ideally, this AajuBaaju My Videos section is for the AajuBaajuians</strong></p>
                            <ol type="1">
                                <li>
                                    <p>who are video content creators such as vloggers, product reviewers, influencers, etc.;</p>
                                </li>
                                <li>
                                    <p>who are good in presenting in front of the camera;</p>
                                </li>
                                <li>
                                    <p>who have excellent video shooting and editing skills.</p>
                                </li>
                            </ol>
                            <p><strong>Let’s do this:</strong></p>
                            <ol type="1">
                                <li>
                                    <p>Should post only under the AajuBaajuians’s own AajuBaaju My Videos name.</p>
                                </li>
                                <li>
                                    <p>AajuBaajuian’s presence saying an introduction "Welcome to AajuBaaju My Videos (Your AajuBaaju My Video Name)" and saying a signoff "Please Share this video. Keep visiting AajuBaaju My Videos (Your AajuBaaju My Video Name)" in all the videos is mandatory.</p>
                                </li>
                                <li>
                                    <p>Every action or static images in the video should be explained in the voice or text format.</p>
                                </li>
                                <li>
                                    <p>Upload a thumbnail image in 1200px by 630px dimension. The image in the thumbnail should have the AajuBaaju My Video Name or a photograph of the AajuBaajuian.</p>
                                </li>
                                {/* <li>
                                    <p>The video, including the inline videos should be shot in wide format.</p>
                                </li> */}
                                <li>
                                    <p>The video should be between 360p and of 720p quality.</p>
                                </li>
                                <li>
                                    <p>The video file size can be a maximum of 200MB.</p>
                                </li>
                                <li>
                                    <p> The video length should be a minimum of 2 minutes without unnecessary pause and still screens before or after the video.</p>
                                </li>
                                <li>
                                    <p>The video should be one’s own or one should have rights to use the video. Send an email to support along with the submissions, if video rights are purchased to be used in one’s content. Please check the copyright policy for details.</p>
                                </li>
                                <li>
                                    <p>The background music used in the video should be one’s own or one should have rights to use the background music. Send an email to support along with the submissions, if background music rights are purchased to be used in one’s content. Please check the copyright policy for details.</p>
                                </li>
                                <li>
                                    <p>The title and the description should be only in English.</p>
                                </li>
                                <li>
                                    <p>Should follow the compliance mentioned in the content policy.</p>
                                </li>
                            </ol>

                            <p><strong>Let’s NOT do this:</strong></p>
                            <ol type="1">
                                <li>
                                    <p>Should not post under other AajuBaajuian’s AajuBaaju My Videos name.</p>
                                </li>
                                <li>
                                    <p>Do not copy video belonging to others from the internet or from any other media.</p>
                                </li>
                                <li>
                                    <p>Do not copy partial video from multiple videos and create video.</p>
                                </li>
                                <li>
                                    <p>Do not partially edit the videos copied from the internet to use in the video.</p>
                                </li>
                                <li>
                                    <p>Do not create text content in video format.</p>
                                </li>
                                <li>
                                    <p>Do use languages other than English in the title or description.</p>
                                </li>
                            </ol>
                            <p>All videos will be pre-moderated by our content moderators’ team and only those videos which are in compliance with our guidelines will be published. The AajuBaajuians will be updated through email about the status of their submissions. <Link to="/content-policy/aajubaaju-my-videos" className={FooterStyle.link}>Click here to see the detailed niche level guidelines</Link></p>
                        </li>
                        <li>
                            <h2>AAJUBAAJU MY CLIPS GUIDELINES:</h2>
                            <p>AajuBaaju provides a platform for the AajuBaajuians to share their knowledge, showcase their talents or exhibit their hobbies through AajuBaaju My Clips to people visiting the platform. The AajuBaajuians can post short video content.</p>
                            <p><strong>Ideally, this AajuBaaju My Clips section is for the AajuBaajuians</strong></p>
                            <ol type="1">
                                <li>
                                    <p>who are video content creators such as vloggers, product reviewers, influencers, etc.;</p>
                                </li>
                                <li>
                                    <p>who are good in presenting in front of the camera;</p>
                                </li>
                                <li>
                                    <p>who have excellent video shooting and editing skills.</p>
                                </li>
                            </ol>
                            <p><strong>Let’s do this:</strong></p>
                            <ol type="1">
                                <li>
                                    <p>Should post only under the AajuBaajuians’s own AajuBaaju My Clips name.</p>
                                </li>
                                <li>
                                    <p>AajuBaajuian’s presence is mandatory.</p>
                                </li>
                                <li>
                                    <p>Every action or static images in the video should be explained in the voice or text format.</p>
                                </li>
                                <li>
                                    <p>Upload a thumbnail image in 540px X 960px dimension. The image in the thumbnail should have the AajuBaaju My Clip Name or a photograph of the AajuBaajuian.</p>
                                </li>
                                <li>
                                    <p>The video should be 1080p quality.</p>
                                </li>
                                <li>
                                    <p>The video file size can be a maximum of 200MB.</p>
                                </li>
                                <li>
                                    <p>The video length should be a MAXIMUM of 1 minute and MINIMUM 15 Seconds without unnecessary pause and still screens before or after the video.</p>
                                </li>
                                <li>
                                    <p>The video clip should be one’s own or one should have rights to use the video. Send an email to support along with the submissions, if video clips rights are purchased to be used in one’s content. Please check the copyright policy for details.</p>
                                </li>
                                <li>
                                    <p>The background music used in the video clip should be one’s own or one should have rights to use the background music. Send an email to support along with the submissions, if background music rights are purchased to be used in one’s content. Please check the copyright policy for details.</p>
                                </li>
                                <li>
                                    <p>The title and the description should be only in English.</p>
                                </li>
                                <li>
                                    <p>Should follow the compliance mentioned in the content policy.</p>
                                </li>
                            </ol>

                            <p><strong>Let’s NOT do this:</strong></p>
                            <ol type="1">
                                <li>
                                    <p>Should not post under other AajuBaajuian’s AajuBaaju My Clips name.</p>
                                </li>
                                <li>
                                    <p>Do not copy video clip belonging to others from the internet or from any other media.</p>
                                </li>
                                <li>
                                    <p>Do not copy partial video clip from multiple videos and create video clip.</p>
                                </li>
                                <li>
                                    <p>Do not partially edit the videos copied from the internet to use in the video clip.</p>
                                </li>
                                <li>
                                    <p>Do not create text content in video format.</p>
                                </li>
                                <li>
                                    <p>Do use languages other than English in the title or description.</p>
                                </li>
                            </ol>

                            <p>All clips will be pre-moderated by our content moderators team and only those videos which are in compliance with our guidelines will be published. The AajuBaajuians will be updated through email about the status of their submissions.</p>
                        </li>
                        <li>
                            <h2>AAJUBAAJU MY REVIEWS GUIDELINES:</h2>
                            <p>AajuBaaju provides a platform for AajuBaajuians to share their experiences through AajuBaaju My Reviews to people visiting the platform. AajuBaajuians can post reviews about a business, product or service in one's own words.</p>
                            <p><strong>Ideally, this AajuBaaju My Reviews section is for the AajuBaajuians</strong></p>
                            <ol type="1">
                                <li>
                                    <p>who are good and honest critics;</p>
                                </li>
                                <li>
                                    <p>who are willing to help the society to identify good and bad;</p>
                                </li>
                                <li>
                                    <p>who has good writing skills in English.</p>
                                </li>
                            </ol>
                            <p><strong>Let’s do this:</strong></p>
                            <ol type="1">
                                <li>
                                    <p>Should post only under the AajuBaajuian’s own AajuBaaju My Reviews name.</p>
                                </li>
                                <li>
                                    <p>Should be written in one’s own words and should have a minimum of 500 words.</p>
                                </li>
                                <li>
                                    <p>Should post at least one image of the business, product or service provider with the presence of the AajuBaajuian in the image.</p>
                                </li>
                                <li>
                                    <p>Business or service providers’ reviews should contain the address of the business or service provider respectively.</p>
                                </li>
                                <li>
                                    <p>Should write quality content which will be helpful for the society with good grammar and correct spellings.</p>
                                </li>
                                <li>
                                    <p>Upload a thumbnail image in 425px by 425px dimension. The image in the thumbnail should have the AajuBaaju My Review Name or a photograph of the AajuBaajuian.</p>
                                </li>
                                <li>
                                    <p>Image width used in the content should be minimum 600px and maximum 800px.</p>
                                </li>
                                <li>
                                    <p>Image height should always be minimum 100px lesser than image width. i.e., the image should be wider, not taller or square.</p>
                                </li>
                                <li>
                                    <p>Image file size can be maximum of 2MB.</p>
                                </li>
                                <li>
                                    <p>Images or photographs should be one’s own or one should have rights to use the images. Send an email to support along with the submissions, if image rights are purchased to be used in one’s content. Please check the copyright policy for details.</p>
                                </li>
                                <li>
                                    <p>The title and the description should be only in English.</p>
                                </li>
                                <li>
                                    <p>Should follow the compliance mentioned in the content policy.</p>
                                </li>
                            </ol>

                            <p><strong>Let’s NOT do this:</strong></p>
                            <ol type="1">
                                <li>
                                    <p>Should not post under other AajuBaajuian’s AajuBaaju My Reviews name.</p>
                                </li>
                                <li>
                                    <p>Do not copy review belonging to others from the internet or from any other media.</p>
                                </li>
                                <li>
                                    <p>Do not copy images belonging to others from the internet or from any other media.</p>
                                </li>
                                <li>
                                    <p>Do not partially edit the images copied from the internet to use in the review.</p>
                                </li>
                                <li>
                                    <p>Do use languages other than English in the title or description.</p>
                                </li>
                            </ol>

                            <p>All reviews will be pre-moderated by our content moderators’ team and only those reviews which are in compliance with our guidelines will be published. The AajuBaajuian will be updated through email about the status of their submissions. <Link to="/content-policy/aajubaaju-my-reviews" className={FooterStyle.link}>Click here to see the detailed niche level guidelines</Link></p>
                        </li>
                        <li>
                            <h2>AAJUBAAJU MY PHOTOS GUIDELINES:</h2>
                            <p>AajuBaaju provides a platform for AajuBaajuians to share their product catalogue, commercial event photos, personal event photos, etc., through AajuBaaju My Photos to people visiting the platform.</p>
                            {/* <p><strong>Ideally, this AajuBaaju My Reviews section is for the AajuBaajuians</strong></p> */}
                            <p><strong>Let’s do this:</strong></p>
                            <ol type="1">
                                <li>
                                    <p>Should post only under the AajuBaajuian’s own AajuBaaju My Photos name.</p>
                                </li>
                                <li>
                                    <p>Should have a brief photo story describing the occasion or purpose of the photos.</p>
                                </li>
                                <li>
                                    <p>Should post minimum of 10 photos. Maximum limit is 50 photos.</p>
                                </li>
                                <li>
                                    <p>Presence of the AajuBaajuian is mandatory in minimum of 10 photos.</p>
                                </li>
                                <li>
                                    <p>Upload a thumbnail image in 425px by 425px dimension. The image in the thumbnail should have the AajuBaaju My Photos Name and the photograph of the AajuBaajuian.</p>
                                </li>
                                <li>
                                    <p>Should have the AajuBaajuian’s presence in the all the photos in case of personal or community events.</p>
                                </li>
                                <li>
                                    <p>In case of product photos by a business, there should be a related business listing. Link to the business listing should be added in the Photo Story</p>
                                </li>
                                <li>
                                    <p>In case of product photos, watermark of business name or logo should be added to all product photos.</p>
                                </li>
                                <li>
                                    <p>Should post photos which are related to each other, i.e., photos of one occasion or purpose.  For example a birthday party or a dance performance.</p>
                                </li>
                                <li>
                                    <p>Photographs should be one’s own or one should have rights to use the Photos. Send an email to support along with the submissions, if image rights are purchased to be used in one’s content. Please check the copyright policy for details.</p>
                                </li>
                                <li>
                                    <p>The title and the description should be only in English</p>
                                </li>
                                <li>
                                    <p>Should follow the compliance mentioned in the content policy.</p>
                                </li>
                            </ol>
                            <p><strong>Let’s NOT do this:</strong></p>
                            <ol type="1">
                                <li>
                                    <p>Should not post under other AajuBaajuian’s AajuBaaju My Photos name.</p>
                                </li>
                                <li>
                                    <p>Do not post photos belonging to others from the internet or from any other media.</p>
                                </li>
                                <li>
                                    <p>Do not post random photos unrelated to each other.</p>
                                </li>
                                <li>
                                    <p>Do use languages other than English in the title or description.</p>
                                </li>
                            </ol>

                            <p>All photos will be pre-moderated by our content moderators’ team and only those photos which are in compliance with our guidelines will be published. The AajuBaajuian will be updated through email about the status of their submissions. <Link to="/content-policy/aajubaaju-my-photos" className={FooterStyle.link}>Click here to see the detailed niche level guidelines</Link></p>
                        </li>
                        <li>
                            <h2>AAJUBAAJU MY ALBUM GUIDELINES:</h2>
                            <p>AajuBaaju provides a platform for AajuBaajuians to share their photos through AajuBaaju My Album to people visiting the platform.</p>
                            <p><strong>Let’s do this:</strong></p>
                            <ol type="1">
                                <li>
                                    <p>Should post only under the AajuBaajuian’s own AajuBaaju My Album name.</p>
                                </li>
                                <li>
                                    <p>Should post a minimum of 25 photos and themaximum limit is 50 photos per album.</p>
                                </li>
                                <li>
                                    <p>Upload a thumbnail image in 425px by 425px dimension. The image in the thumbnail should have the AajuBaaju My Albumname or the photograph of the AajuBaajuian.</p>
                                </li>
                                <li>
                                    <p>Should have the AajuBaajuian’s presence prominently in minimum of 25 photos. Post only the photos matching the profile photo.</p>
                                </li>
                                <li>
                                    <p>Photographs should be one’s own or one should have rights to use the photos. Please check the copyright policy for details.</p>
                                </li>
                                <li>
                                    <p>Should follow the compliance mentioned in the content policy.</p>
                                </li>
                            </ol>
                            <p><strong>Let’s NOT do this:</strong></p>
                            <ol type="1">
                                <li>
                                    <p>Should not post under other AajuBaajuian’s AajuBaaju My Album name.</p>
                                </li>
                                <li>
                                    <p>Do not post photos belonging to others from the internet or from any other media.</p>
                                </li>
                                <li>
                                    <p>Do not post old photos where we will be unable to match the AajuBaajuian with their profile photo.</p>
                                </li>
                            </ol>

                            <p>All photos will be pre-moderated by our content moderators’ team and only those photos which are in compliance with our guidelines will be published. The AajuBaajuian will be updated through email about the status of their submissions.</p>
                        </li>
                        <li>
                            <h2>LANGUAGE GUIDELINES:</h2>
                            <p>Presently, AajuBaaju is compatible only in English. We will be releasing in regional languages very soon.</p>
                            <p>The text content in all the listings should be only in the English language.
                                The images and the videos can have regional languages.
                            </p>
                        </li>
                        <li>
                            <h2>DISCLAIMER:</h2>
                            <p>The AajuBaajuian acknowledges the following:</p>
                            <ol type='none'>
                                <li>
                                    <p>Ownership of the content and images/photos used.</p>
                                </li>
                                <li>
                                    <p>Correctness and authenticity of the facts mentioned.</p>
                                </li>
                                <li>
                                    <p>Possess appropriate permission to use others' intellectual properties.</p>
                                </li>
                            </ol>
                            <p>
                                The AajuBaajuian is solely responsible for the discrepancies or legalities arising there off from any of their content in any form posted in aajubaaju.in. aajubaaju.in or its associates will have no obligations to protect the AajuBaajuian in any manner in this regard.
                            </p>
                        </li>
                    </ol>
                </div>
                <div className="listings_footer row">
                    <div className={`${styles.large_rect}`}>
                        <LargeRectangle spot={"-1"} />
                    </div>
                    <div className={`${styles.billBoard}`}>
                        <BillBoard spot={"-1"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

