import React, { useEffect } from 'react';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'
import { ButtonStyle } from '../UIElements/Buttons/ButtonStyle';

export const ReviewsGuidelines = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="grid_container terms_of_service">
            <div className="row">
                <div className="col-md-12">
                    <ol type="I" className={FooterStyle.margin}>
                        <h2 style={{'margin-top': '40px'}}>General Guidelines:</h2>
                        <h2>Niche Level Guidelines for AajuBaaju My Reviews:</h2>
                        <li>
                            <h2>Gadget Reviews:</h2>
                            <ol type="1">
                                <li>
                                    <p>Should upload one photo of the gadget along with the AajuBaajuian and one same product photo.</p>
                                </li>
                                <li>
                                    <p>The 500 words should exclude the product specifications. Products specifications and reitereation of product specifications will not be considered for word count.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h2>Restaurant Reviews:</h2>
                            <ol type="1">
                                <li>
                                    <p>Should upload one photo of AajuBaajuian in front of the name of the restaurant and one photo of AajuBaajuian having food inside the restaurant.</p>
                                </li>
                                <li>
                                    <p>Address and contact details of the restaurant.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h2>Shop Reviews:</h2>
                            <ol type="1">

                                <li>
                                    <p>Should upload one photo of AajuBaajuian in front of the name of the shop and one photo of AajuBaajuian inside the shop with the owner or authorized person.</p>
                                </li>
                                <li>
                                    <p>Address and contact details of the shop.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <h2>Location Reviews:</h2>
                            <ol type="1">

                                <li>
                                    <p>Should upload one photo of AajuBaajuian from the location.</p>
                                </li>
                                <li>
                                    <p>Should review about specific details and facilities which would help others in visiting the location.</p>
                                </li>
                            </ol>
                        </li>

                        <li>
                            <h2>IMPORTANT NOTE:</h2>
                            <p>We will be adding/updating the guidelines as and when we come across a need, based on the user behaviour. It is every AajuBaajuian's reponsibility to check the niche guidelines every time before they create a write a review content.</p>
                        </li>
                    </ol>
                    <div className='row' style={{paddingBottom:'5px'}}>
                        <div className='col-md-9'></div>
                        <div className="col-md-3 add_button animated_back_button">
                            <ButtonStyle type="button" className="form-control btn btn-block btn_font"
                                handleClick={() => props.history.goBack()}>&nbsp; &laquo; &nbsp;Go Back </ButtonStyle>
                        </div>
                    </div>
                </div>
                <div className="listings_footer row">
                    <div className={`${styles.large_rect}`}>
                        <LargeRectangle spot={"-1"} />
                    </div>
                    <div className={`${styles.billBoard}`}>
                        <BillBoard spot={"-1"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

