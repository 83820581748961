import React, { Component } from 'react';
import axios from 'axios'
import BillBoard from '../../advertisements/BillBoard';
import authservice from '../../Authentication/service/authservice';
import DateRangePicker from 'react-daterange-picker';
import "react-daterange-picker/dist/css/react-calendar.css";
import moment from 'moment';
import { TitleHeading } from '../../UIElements/headings/TitleHeading';
import { ButtonStyle } from '../../UIElements/Buttons/ButtonStyle';
import styles from '../../listings/forms/addpost.module.css';
import './performance.css'
import LargeRectangle from '../../advertisements/LargeRectangle';

class ViewEarning extends Component {

    constructor(props) {
        super(props);
        this.state = {
            withdrawal: [],
            dateRange: '',
            dateValue: '',
        }
    }

    componentDidMount = () => {
        window.scroll(0, 0);
        this.setState({
            dateRange: moment.range([moment().subtract('7', 'days'), moment().subtract('1', 'days')])
        }, () => {
            this.renderDateRangeValue();
        });

        this.currentUserId = this.props.isAdmin ? this.props.userId : authservice.getCurrentUserid()

    }

    selectDate = (dateRange) => {
        this.setState({
            dateRange,
            isDatePickerOpen: !this.state.isDatePickerOpen,
        }, () => {
            this.renderDateRangeValue();
        });
    };

    renderDateRangeValue = () => {
        this.currentUserId = this.props.isAdmin ? this.props.userId : authservice.getCurrentUserid()
        if (this.state.dateRange) {
            const start = this.state.dateRange.start.format("DD-MM-YYYY");
            const end = this.state.dateRange.end.format("DD-MM-YYYY");
            if (start === end)
                this.setState({ dateValue: this.state.dateRange.start.format("DD/MM/YYYY") });
            else
                this.setState({ dateValue: this.state.dateRange.start.format("DD/MM/YYYY") + " - " + this.state.dateRange.end.format("DD/MM/YYYY") });
            axios.get(process.env.REACT_APP_BACKEND_URL + `/abmgr/revenue/user-tds-report?userId=${this.currentUserId}&fromDate=${start}&toDate=${end}`)
                .then(response => {
                    if (response.data) {
                        console.log(response.data)
                        const withdrawal = response.data ? response.data : []
                        this.setState({ withdrawal })
                    }
                }).catch(error => {
                    console.log(error)
                })
        }
    };


    renderwithdrawalhistory() {
        return this.state.withdrawal.map((user, index) => {
            const { transactionDate, earnedAmount, tds, payableAmount } = user //destructuring
            return (
                <tr id={'parent-row-' + transactionDate} className="table-row extra_height" key={transactionDate}>
                    <td>{index + 1}</td>
                    <td>{transactionDate}</td>
                    <td>{earnedAmount}</td>
                    <td>{tds}</td>
                    <td>{payableAmount}</td>
                </tr>
            )
        })
    }

    render() {
        return (
            <>
                <div className='desktop_viewreport'>
                    <div className='ad_container'>
                        <div>
                            <div className='ViewPerformance'>
                                <TitleHeading text={'Withdrawal History'} />
                            </div>
                            <div className="col-md-3 date_range_picker low_padding" style={{ margin: '20px 0 5px 40px' }}>
                                <div className="date_range_picker_input" onClick={() => this.setState({ isDatePickerOpen: !this.state.isDatePickerOpen })}>
                                    <i className="fa fa-calendar cal_icon" aria-hidden="true"></i>
                                    <input type="text" className="form-control white_readonly" id="date_search" name="username_search"
                                        placeholder="Date Range"
                                        value={this.state.dateValue}
                                        readOnly
                                    />
                                </div>
                                {this.state.isDatePickerOpen && (
                                    <div className="date_picker" style={{ left: '-40px' }}>
                                        <DateRangePicker
                                            value={this.state.dateRange}
                                            onSelect={this.selectDate}
                                            singleDateRange={true}
                                            maximumDate={new Date()}
                                        />
                                    </div>
                                )}
                            </div>
                            <table id='posts' className="col-md-12 tr_marginTop">
                                <tbody>
                                    <tr className="table-row extra_height">
                                        <th className='td_width'>S.No.</th>
                                        <th className='user_width'>Date of Withdrawal</th>
                                        <th className='user_width'>Amount Withdrawn</th>
                                        <th className='user_width'>TDS Deducted</th>
                                        <th className='joindate_width'>Amount To Be Transferred</th>
                                    </tr>
                                    {this.renderwithdrawalhistory()}
                                </tbody>
                            </table>
                            <div className="ViewPerformance listing_type_tabs">
                                <div className="col-md-9"></div>
                                {!this.props.isAdmin
                                    && <div className="col-md-3 add_button animated_back_button">
                                        <ButtonStyle type="button" className="form-control btn btn-block btn_font"
                                            handleClick={() => this.props.history.goBack()}>&nbsp; &laquo; &nbsp; Go Back To Dashboard </ButtonStyle>
                                    </div>}
                            </div>
                        </div>

                    </div>
                    {!this.props.isAdmin &&
                        <div className='ad_marginTop'>
                            <BillBoard spot={"-1"} />
                        </div>}
                </div>
                <div className='mobile_viewreport'>
                    <div className='ad_container'>
                        <div>
                            <div className='ViewPerformance'>
                                <TitleHeading text={'Withdrawal History'} />
                            </div>
                            <table id='posts' className="col-md-12 tr_marginTop">
                                <tbody>
                                    <tr className="table-row extra_height">
                                        <th className='td_width'>S.No.</th>
                                        <th className='user_width'>Date of Withdrawal</th>
                                        <th className='user_width'>Amount Withdrawn</th>
                                        <th className='user_width'>TDS Deducted</th>
                                        <th className='joindate_width'>Amount To Be Transferred</th>
                                    </tr>
                                    {this.renderwithdrawalhistory()}
                                </tbody>
                            </table>
                            <div className="ViewPerformance listing_type_tabs">
                                <div className="col-md-9"></div>
                                {!this.props.isAdmin
                                    && <div className="col-md-3 add_button animated_back_button">
                                        <ButtonStyle type="button" className="form-control btn btn-block btn_font"
                                            handleClick={() => this.props.history.goBack()}>&nbsp; &laquo; &nbsp; Back</ButtonStyle>
                                    </div>}
                            </div>
                        </div>

                    </div>
                    <div className={`listings row ${styles.large_rect}`}>
                        {!this.props.isAdmin && <LargeRectangle spot={"-1"} />}
                    </div>
                </div>
            </>
        )
    }
}

export default ViewEarning;