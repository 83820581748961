import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BillBoard from '../advertisements/BillBoard';
import LargeRectangle from '../advertisements/LargeRectangle';
import styles from './cover.module.css';
import { TitleHeading } from '../UIElements/headings/TitleHeading';
import listingService from '../listings/service/listingService';
import { useViewportBillBoard } from '../utility/useViewport';
import { CardsListings } from './CardsListings';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const RecentListings = (props) => {

    const coords = useSelector(state => state.current_coords);
    const [listings, setListings] = useState([]);
    const viewedListings = useSelector(state => state.viewedListings);
    const isBillboard = useViewportBillBoard();

    const heading = {
        business: 'Business Listings',
        event: 'Event Listings',
        job: 'Job Listings',
        news: 'Pages',
        reviews: 'Reviews',
        article: 'My Videos',
        photos: 'Photos',
        album: 'Album'
    }

    const getListingDetails = (listing) => {
        const searchCriteria = listingService.getListingSettings(listing, coords);
        const businessName = listing.businessName.replace(/[^\w\s^&]/gm, ' ').trim().replace(/[\s]/gm, '-')
        const listingPath = encodeURI(`/l/${businessName}/${listing.hpId}`);
        props.history.push(listingPath, {
            place: null,
            isViewed: viewedListings.includes(listing.hpId),
            searchCriteria: searchCriteria,
        });
    }

    useEffect(() => {
        // if (!coords.latitude)
        //     return;

        listingService.getAllListingsContent(coords, props.listingType).then(data => {
            //.sort((a, b) => moment(b.createdDate).diff(moment(a.createdDate)))
            setListings(data);
        })
    }, [coords])

    return (
        listings.length > 0 ?
            <div>
                <TitleHeading text={`Recently Added ${heading[props.listingType]}`} />
                <div className="row">
                    <CardsListings listings={listings}
                        showContent={getListingDetails}
                        viewedContentIds={viewedListings}
                        type={props.listingType} />
                    {props.listingType === 'news' ?
                        <div className="d-flex my-3 pl-3">
                            <Link className={styles.read_more_button} to={`/contents/${props.match.params.username}/pages`}>
                                Click here to See more...
                            </Link>
                        </div> : props.listingType === 'photos' ?
                            <div className="d-flex my-3 pl-3">
                                <Link className={styles.read_more_button} to={`/contents/${props.match.params.username}/photos`}>
                                    Click here to See more...
                                </Link>
                            </div> : props.listingType === 'album' ?
                                <div className="d-flex my-3 pl-3">
                                    <Link className={styles.read_more_button} to={`/contents/${props.match.params.username}/album`}>
                                        Click here to See more...
                                    </Link>
                                </div> : props.listingType === 'reviews' ?
                                    <div className="d-flex my-3 pl-3">
                                        <Link className={styles.read_more_button} to={`/contents/${props.match.params.username}/reviews`}>
                                            Click here to See more...
                                        </Link>
                                    </div> : null}
                </div>
            </div> : <></>
    )
}

export default RecentListings;