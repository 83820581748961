const initalState = {
    isAdmin: '',
    isMobile: false,
    isTablet: false,
    isBanner: false,
    isBillboard: false,
    post_data: [],
    featuredImage: null,
    all_categories: [],
    full_address: [],
    listing_id: null,
    set_address: [],
    listing_type: '',
    search_data: {},
    searching_counts: {
        business: 0,
        event: 0,
        job: 0,
        news: 0,
        article: 0,
        reviews: 0,
        photos: 0,
    },
    lookUpPageState: null,
    listingFieldsData: null,
    saved_listing_id: 0,
    is_login: false,
    content_page: '',
    current_coords: {},
    serach_criteria: null,
    popUpAds: [],
    isProfileUpdated: false,
    ad_data: [],
    currentPost: [],
    billBoardAds: [],
    largeRectangleAds: [],
    viewedAds: [],
    viewedListings: [],
    uploadingSliderImage: false,
    isPopupOpen: false,
    isPageVisible: true,
    isLoading: false,
    visibleAds: [],
    storedBillBoard: [],
    storedLR: [],
}

const reducer = (state = initalState, action) => {

    if (action.type === 'STORED_BB_ADS') {

        return {
            ...state,

            storedBillBoard: action.storedBillBoard

        }
    }
    if (action.type === 'STORED_LR_ADS') {

        return {
            ...state,

            storedLR: action.storedLR

        }
    }
    if (action.type === 'SEND_DATA') {

        return {
            ...state,

            post_data: action.post_data

        }
    }
    if (action.type === 'SEND_IMAGE') {

        return {
            ...state,

            featuredImage: action.featuredImage

        }
    }
    if (action.type === 'all_categories') {

        return {
            ...state,

            all_categories: action.all_categories

        }
    }
    if (action.type === 'full_address') {

        return {
            ...state,

            full_address: action.full_address

        }
    }
    if (action.type === 'listing_id') {

        return {
            ...state,

            listing_id: action.listing_id

        }
    }
    if (action.type === 'set_address') {
        return {
            ...state,

            set_address: action.set_address

        }
    }

    if (action.type === 'Listing') {
        return {
            ...state,

            listing_type: action.listing_type

        }
    }

    if (action.type === 'Seraching_Data') {
        return {
            ...state,

            search_data: action.search_data
        }
    }

    if (action.type === 'Seraching_Counts') {
        return {
            ...state,

            searching_counts: action.searching_counts
        }
    }

    if (action.type === 'LOOKUP_PAGE_STATE') {
        return {
            ...state,

            lookUpPageState: action.lookUpPageState
        }
    }

    if (action.type === 'SEARCH_LISTING_FIELDS') {
        return {
            ...state,

            listingFieldsData: action.listingFieldsData
        }
    }
    if (action.type === 'SAVED_LISTING_ID') {
        return {
            ...state,

            saved_listing_id: action.id
        }
    }

    if (action.type === 'IS_LOGIN') {
        return {
            ...state,

            is_login: action.is_login
        }
    }

    if (action.type === 'CONTENT_PAGE') {
        return {
            ...state,

            content_page: action.content_page

        }
    }

    if (action.type === 'CURRENT_LOCATION') {
        return {
            ...state,

            current_coords: action.current_coords

        }
    }

    if (action.type === 'SEARCH_CRITERIA') {
        return {
            ...state,

            serach_criteria: action.serach_criteria

        }
    }

    if (action.type === 'PROFILE_PHOTO') {
        return {
            ...state,
            profilePhoto: action.profilePhoto
        }
    }

    if (action.type === 'IS_PROFILE_UPDATED') {

        return {
            ...state,

            isProfileUpdated: action.isProfilePhotoUpdated

        }
    }

    if (action.type === 'ADVERTISE_DATA') {

        return {
            ...state,

            ad_data: action.ad_data
        }
    }

    if (action.type === 'BILLBOARD_ADS') {
        return {
            ...state,

            billBoardAds: action.billBoardAds
        }
    }

    if (action.type === 'LARGE_RECTANGLE_ADS') {
        return {
            ...state,

            largeRectangleAds: action.largeRectangleAds
        }
    }

    if (action.type === 'CLIPS_ADS') {
        return {
            ...state,

            clipAdAds: action.clipAdAds
        }
    }

    if (action.type === 'VIEWED_ADS') {
        return {
            ...state,

            viewedAds: [...state.viewedAds, action.viewedAds]
        }
    }

    if (action.type === 'ALL_VIEWED_ADS') {
        return {
            ...state,

            viewedAds: action.viewedAds
        }
    }

    if (action.type === 'VIEWED_LISTINGS') {
        return {
            ...state,

            viewedListings: action.viewedListings
        }
    }

    if (action.type === 'POPUP_ADS') {
        return {
            ...state,

            popUpAds: action.popUpAds
        }
    }

    if (action.type === 'POPUP_OPEN_NOTIFICATION') {

        return {
            ...state,

            isPopupOpen: action.isPopupOpen,

        }
    }

    if (action.type === 'ADMIN_LOGIN') {

        return {
            ...state,

            isAdmin: action.isAdmin,

        }
    }

    if (action.type === 'MOBILE_USER') {

        return {
            ...state,

            isMobile: action.isMobile,

        }
    }

    if (action.type === 'TABLET_USER') {

        return {
            ...state,

            isTablet: action.isTablet,

        }
    }

    if (action.type === 'BANNER') {

        return {
            ...state,

            isBanner: action.isBanner,

        }
    }

    if (action.type === 'BILLBOARD_USER') {

        return {
            ...state,

            isBillboard: action.isBillboard,

        }
    }

    if (action.type === 'CURRENT_POST') {

        return {
            ...state,

            currentPost: action.currentPost,

        }
    }

    if (action.type === 'UPLOADING_SLIDER') {

        return {
            ...state,

            uploadingSliderImage: action.uploadingSliderImage,

        }
    }

    if (action.type === 'PAGE_VISIBILITY') {

        return {
            ...state,

            isPageVisible: action.isPageVisible,

        }
    }

    if (action.type === 'LOADING') {

        return {
            ...state,

            isLoading: action.isLoading,

        }
    }

    if (action.type === 'VISIBLE_ADS') {

        return {
            ...state,

            visibleAds: action.visibleAds
        }
    }

    if (action.type === 'CLEAR_DATA') {
        return {
            ...state,
            post_data: [],
            total: [],
            featuredImage: null,
            listing_id: null,
            set_address: [],
            //profilePhoto: '',
            ad_data: [],
            uploadingSliderImage: false,
        }
    }

    return state
}

export default reducer;